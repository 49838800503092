/*SEARCH RESULTS----------------------------------------------------------------//
--------------------------------------------------------------------------------*/

.search-results{

    .kstl-search-list{
        padding-top:40px !important;
        display:flex !important;
        flex-direction:row;
        flex-wrap:wrap;

        .search-result {
            display:flex;
            flex:0 1 auto;
            margin: 10px 0;

            .search-result__item {
                display:flex;
                flex:0 1 auto;
                align-self: flex-end;
                align-self: stretch;
                padding: 10px !important;

                a.search-result__link{
                    display:flex;
                    flex:1;
                    align-self: stretch;
                    flex-direction:column;
                    align-content:center;
                    text-decoration:none;
                    color:$kBlack;
                    border-radius:10px;
                    border:1px solid $borderGrey;
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+70,f7f7f7+100 */
                    background: rgb(255,255,255); /* Old browsers */
                    background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 70%, rgba(247,247,247,1) 100%); /* FF3.6-15 */
                    background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 70%,rgba(247,247,247,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: radial-gradient(ellipse at center, rgba(255,255,255,1) 70%,rgba(247,247,247,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                    min-height: 300px;

                    img{
                        display:block;
                        border-radius:10px 10px 0px 0px;
                    }//IMG

                    img.brand{
                        display:block;
                        border-radius:none;
                        margin-top:60px;
                    }//IMG

                    img.product{
                        display:block;
                        border-radius:none;
                        width:80%;
                    }//IMG

                    h3{
                        margin:10px;
                        text-align:center;
                    }//H3

                    &:hover{
                        text-decoration:underline;
                        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
                    }
                }//LINK
            }//RESULT ITEM

            @media (max-width:$lap){
                width:100%;
                display:block;

                .search-result__item{
                    display:block;

                    a.search-result__link{
                        display:block;
                        text-align:center;

                        img{
                            width:100%;
                            height:auto;
                        }

                    }
                }

            }//600

        }//RESULTS CONATINER

    }//KSTL-SEARCH-LIST

/*SEARCH RESULTS----------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/