.mosaic-main-wrapper {
    background: transparent url(/content/dam/Australia/kelloggs_au/images/wood-bg-sd2.png) 50% 100% no-repeat;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 100px !important;

/*HEADING-----------------------------------------------------------------------*/
    .heading {
        margin-top: -125px;

        .item {
            float: left;
        }//ITEM

        .item.operator {
            float: right;
            margin-top: 50px;
        }//OPERATOR

        .item.blurb {
            float: right;
            margin-top: 62px;
            margin-right: 25px;
            @extend %large;
            color: #262626;
            font-family: KelloggsSansRegular;
        }//BLURB


    }//HEADING


/*MOSAIC TILES WRAPPER----------------------------------------------------------*/  

    .mosaic-tiles-wrapper {   
        max-width: 980px;
        margin: 0 auto !important;
        padding: 100px 0 100px 0 !important;

        .grid__item{
            
            .cta {
                box-sizing: border-box;
                position: relative;
                padding: 0 0 10px 0;
                overflow: hidden;

                .cta_image{
                    .kstl-image-wrapper{
                        .kstl-responsive-image{
                            img {
                                padding: 0 !important;
                            }//IMG
                        }//KSTL RESPONSIVE IMAGE
                    }//IMAGE WRAPPER
                }//CTA IMAGE

            }//CTA

        }//GRID ITEM



    }//TILED WRAPPER

/*-------------------------------------------------------MAIN WRAPPER (operator)*/
    .operator {
        text-align: center;
        margin-top: 20px;

        .view-all {
            display: inline-block;
            @extend %small;
            text-decoration: none;
            text-transform: uppercase;
            color: #262626;
            padding-bottom: 2px;
            border-bottom: 2px solid #262626;
            font-family: KelloggsSansMedium;
        }//VIEW ALL

    }//OPERATOR


/*---------------------------------------------------MAIN WRAPPER (tile content)*/
    .tile-content {
        position: absolute;
        top: 60px;
        left: 40px;
        width: 236px;
        height: 280px;
        color: #262626;

/*-----------------------------------------------------------TILE CONTENT (name)*/
        .name{
            background: transparent url(/content/dam/Australia/kelloggs_au/images/icon-author.png) 0 0 no-repeat;
            padding-left: 30px;
            padding-bottom: 5px;
            text-transform: uppercase;
            @extend %extraSmall;
            color: white;

        }//NAME

/*----------------------------------------------------------TILE CONTENT (quote)*/
        .quote{
            @extend %medium;
            line-height: 1.8;
            margin: 20px 0;
            text-align: right;
            color: white;
        }//QUOTE

/*-------------------------------------------------------TILE CONTENT (operator)*/
        .operator{
            position: absolute;
            bottom: 18px;
            left: 0;
            width: 100%;

            .view-answer {
                text-transform: uppercase;
                text-decoration: none;
                color: white;
                @extend %extraSmall;

                &:hover {
                    text-decoration: underline;
                }//HOVER
            }//VIEW ANSWER
        }//OPERATOR
    }//TILE CONTENT


/*MOSAIC CAROUSEL WRAPPER-------------------------------------------------------*/  
    .mosaic-carousel-wrapper{
        padding-bottom: 60px !important;

        .heading {
            position: relative;
            width: 380px;
            margin: 0 auto;

            .item.image {
                float: none;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50px);
            }//ITEM IMAGE

            .item.operator {
                margin-bottom: 20px;
            }//ITEM OPERATOR
        }//HEADING

/*------------------------------------------MOSAIC CAROUSEL WRAPPER (flexslider)*/
        .flexslider {
            margin-top: 0;

            .flex-viewport {
                width: 316px;
                margin: 0 auto;
            }//FLEX VIEWPORT
        }//FLEXSLIDER

/*------------------------------------MOSAIC CAROUSEL WRAPPER (full image slide)*/
        .kstl-full-image-slide {
            position: relative;
            background-color: transparent;

            .kstl-feature-inset {
                position: static;
                background-image: none;
                background-color: transparent;

                .kstl-rich-text {
                    height: 0;
                    padding: 0 !important;
                }//KSTL RICH TEXT

            }//KSTL FEATURE INSET

        }//KSTL FULL IMAGE SLIDE

    }//CAROUSEL WRAPPER


    @media (max-width: 999px) {
            margin-left: -20px;
            margin-right: -20px;
    }//999



/*MOSAIC MAIN WRAPPER-----------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/