header.is-sticky {
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	width: 100%;
	
	@media (min-width:$packBreak) {	
		.topNav {
			max-width: none;
			
			.top-menu-container {
				position: relative;
			
				.kstl-mega-nav {
					display: none !important; 
				}
				
				nav {
					position: absolute;
						left: 50% !important;
						transform: translateX(-50%);
						
					.KSTLNav > li.nav-button {	
						& > a {							
							background: none;
							display: block;
							margin: 0 10px;
							padding: 8px 16px;
							text-transform: uppercase;
							color: #2c2c2c;
							border-radius: 9999px;
							font-family: KelloggsSansRegular !important;
							font-size: 12px !important;
							
							&:hover, &.active {
								background-color: #2c2c2c;
								color: #fff;
							}
						}
						
						&:after {
							display: none;
						}
					}						
				}				
			}
		}
	}
}

.sticky-nav .nav {	
	margin: 10px auto 0;
	text-align: center;
	
	ul li a {
		background: none;
		display: block;
		margin: 0 10px;
		padding: 8px 16px;
		text-transform: uppercase;
		color: #2c2c2c;
		border-radius: 9999px;
		font-family: KelloggsSansRegular !important;
		font-size: 12px !important;
		
		&:hover, &.active {
			background-color: #2c2c2c;
			color: #fff;
		}
	}	
}