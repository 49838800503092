@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}


/*REM UNITS WITH PX FALLBACK----------------------------------------------------*/
@mixin mySize($property, $values...) {
    $n: length($values);
    $i: 1;

    $pxlist: ();
    $remlist: ();

    @while $i <= $n {
        $itemVal: (nth($values, $i));
        @if $itemVal != "auto"{
            $pxlist: append($pxlist, $itemVal + px);
            $remlist: append($remlist, ($itemVal / 10) + rem);
        }@else{
            $pxlist: append($pxlist, auto);
            $remlist: append($remlist, auto);
        }


        $i: $i + 1;
    }

    #{$property}: $pxlist;
    #{$property}: $remlist;
}

/*LINEAR GRADIENTS--------------------------------------------------------------//
--------------------------------------------------------------------------------*/
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/*TEST IF VALUE IS AN ANGLE-----------------------------------------------------*/
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/*Convert a direction to legacy syntax------------------------------------------*/
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
  }

  @mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction) == false {
      $color-stops: ($direction, $color-stops);
      $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
  }

/*TESTS-------------------------------------------------------------------------*/
.test-1 {
  @include linear-gradient(#31B7D7, #EDAC7D);
}

.test-2 {
  @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
}

.test-3 {
  @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
}



/*MEDIA QUERIES-----------------------------------------------------------------*/

@mixin media-query($media-query){

  @if $media-query == tiny{
	   @media (max-width: $tiny) { @content; }
  }

  @if $media-query == palm{
	   @media (max-width: $palm) { @content; }
  }

  @if $media-query == lap{
	   @media (max-width: $lap) { @content; }
  }

  @if $media-query == innerBreak{
	   @media (max-width: $innerBreak) { @content; }
  }

  @if $media-query == desk{
     @media (max-width: $desk) { @content; }
  }

  @if $media-query == wide{
     @media (min-width: $wide) { @content; }
  }
}//END MEDIA QUERIES


/*ADD VENDOR PREFIXES-----------------------------------------------------------*/
@mixin vendor($property, $value...){
  -webkit-#{$property}:$value;
  -moz-#{$property}:$value;
  -ms-#{$property}:$value;
  -o-#{$property}:$value;
  #{$property}:$value;
}

/*clearFix----------------------------------------------------------------------*/

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}