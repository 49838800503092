.health-detail{

    p{
        padding-bottom:20px;    
    }

    h2{
        line-height:1;
        padding-bottom:20px;
    }


/*CAROUSEL----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/

.kstl-feature-inset{

/*SLIDE ONE---------------------------------------------------------------------*/
        .slideOne{
            margin:40px 0 0 calc((100vw / 96) * 5);
            width:500px;
            color:$kWhite;
            

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
                padding-bottom:0px; 
            }//P

/*---------------------------------------------------SLIDE ONE MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            color:$kBlack;
            margin:0px 0 0 calc((100vw / 95) * 2);
            padding: 40px 0 0 0;
        }//950
            
/*---------------------------------------------------SLIDE ONE MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE ONE

/*SLIDE LIGHT------------------------------------------------------------------*/
        .slideLight{
            background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0) 72%, rgba(0,0,0,0) 100%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.25) 50%,rgba(0,0,0,0) 72%,rgba(0,0,0,0) 100%);
            background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.25) 50%,rgba(0,0,0,0) 72%,rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=1 );
            
            width: 500px;
            color: $kWhite;
            padding:40px 0 40px 80px;

            h1{
                font-family: KelloggsSansMedium;
                font-size: calc((100vw / 300) * 11);
                line-height: 1;
            }//h1

            p{
                margin-top: 10px;
                line-height: 1.1;
                padding-bottom: 0px; 
            }//P
/*-------------------------------------------------SLIDE LIGHT MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            background: transparent;
            padding: 40px 0 0 0;
            color: $kBlack;
            margin: 0px 0 0 calc((100vw / 95) * 2);
        }//950
            
/*-------------------------------------------------SLIDE LIGHT MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE LIGHT


/*SLIDE DARK------------------------------------------------------------------*/
        .slideDark{
            background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 72%, rgba(255,255,255,0) 100%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 72%,rgba(255,255,255,0) 100%);
            background: radial-gradient(ellipse at center, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 72%,rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=1 );            
            width: 370px;
            padding: 40px;
            //border: 1px solid red;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
                padding-bottom:0px; 
            }//P
/*--------------------------------------------------SLIDE DARK MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            padding: 40px 0 0 0;
            color:$kBlack;
            margin:0px 0 0 calc((100vw / 95) * 2);
        }//950
            
/*--------------------------------------------------SLIDE DARK MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE DARK


/*SLIDE DARK WIDE---------------------------------------------------------------//
--------------------------------------------------------------------------------*/
        .slideDarkWide{
            background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 72%, rgba(255,255,255,0) 100%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 72%,rgba(255,255,255,0) 100%);
            background: radial-gradient(ellipse at center, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 72%,rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=1 );
            
            width: 500px;
            padding: 0 40px;
            //border: 1px solid red;

            h1{
                font-family: KelloggsSansMedium;
                font-size: calc((100vw / 300) * 11);
                line-height: 1;
            }//h1

            p{
                margin-top: 10px;
                line-height: 1.1;
                padding-bottom: 0px; 
            }//P
/*---------------------------------------------SLIDE DARK WIDE MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            padding: 40px 0 0 0;
            width: 90%;
            color: $kBlack;
            margin: 0 0 0 calc((100vw / 95) * 2);
        }//950
            
/*---------------------------------------------SLIDE DARK WIDE MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE DARK WIDE



        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV


/*NON CAROUSEL SLIDE------------------------------------------------------------//
--------------------------------------------------------------------------------*/
.kstl-feature-inset{

/*HERO--------------------------------------------------------------------------*/
        .hero{
            margin:40px 0 0 calc((100vw / 96) * 5);
            width:500px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
                padding-bottom:0px; 
            }//P

/*---------------------------------------------------SLIDE ONE MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            color:$kBlack;
            margin:0px 0 0 calc((100vw / 95) * 2);
        }//950
            
/*---------------------------------------------------SLIDE ONE MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:20px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE ONE

    }//KSTL FEATURE INSET





/*TEXT DIRECTIONS---------------------------------------------------------------*/
    .text-left{
        padding-right:40px;

        @media(max-width:$lap){
            padding-right:0px;
        }
    }//TEXT LEFT


    .text-right{
        padding-left:40px;

        @media(max-width:$lap){
            padding-left:0px;
        }
    }//TEXT RIGHT


/*PROTEIN TABS------------------------------------------------------------------//
        Everything below this point is largely for the Power of Protein page.   //
--------------------------------------------------------------------------------*/
    .protein-tabs{
        padding:20px 20px 60px 20px;

        @media (max-width:$lap){
            padding:0px 20px 60px 20px;
        }

/*-----------------------------------------------------------DESKTOP TAB BUTTONS*/
        .tab_buttons{
            .tab_button{
                margin:0px 6px;

                a.tab_link{
                   background-color: #f7f7f7;
                    border: 1px solid #ddd;
                    border-radius: 999px;
                    color: #666 !important;
                    font-size: 12px !important;
                    display: inline-block;
                    margin: 4px;
                    padding: 12px 30px;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover{
                        background-color: #262626;
                        border-color: #262626;
                        color: #f7f7f7 !important;
                    }//HOVER 
                }

                @media (max-width:880px){
                    margin:10px 6px;
                }
                
            }//TAB BUTTON
            
            .activeLink{
                a.tab_link{
                    background-color: #262626;
                    border-color: #262626;
                    color: #f7f7f7 !important;
                }
            }//ACTIVE LINK

            @media (max-width:$lap){
                background-image:none;
            }

            @media (max-width:$lap){
                display:none;
            }
        }//TAB BUTTONS

/*-------------------------------------------------------------------TAB WRAPPER*/
        .tab_wrapper{

            .tab_content{
                padding:0px 40px;

                .tab_inner{

                    .cta{
                        padding:20px;

                        .cta_image{

                            .kstl-image-wrapper{
                                .kstl-responsive-image{

                                    img{
                                        margin:0px auto;
                                        width:unset;
                                    }
                                }//KSTL RESPONSIVE IMAGE
                            }//KSTL IMAGE WRAPPER
                            

                        }//CTA IMAGE

                        

                        .cta_text{

                            .kstl-rich-text{
                                text-align:center;
                                position:relative;
                                

                                p{
                                    @extend %extraSmall;
                                    display:inline-block;
                                    padding:10px 6px;
                                }//P

                                p.dot{
                                    position: absolute;
                                    top: 0;
                                    color: #ffffff;
                                    background: #b41946;
                                    width: 36px;
                                    height: 36px;
                                    text-align: center;
                                    border-radius: 60px;
                                    padding: 9px 0 0 0;
                                }//DOT

                            }//KSTL RICh TEXT

                        }//CTA TEXT

                    }//CTA
                }//TAB INNER

/*-------------------------------------------------TAB CONTENT MEDIA QUERY (600)*/
                @media (max-width:$lap){
                    padding:0px 0;


/*------------------------------------------------------------MOBILE TAB BUTTONS*/
                    .mobile_tab_button{
                        display:block;

                        a.mobile_tab_link{
                            width:100%;
                            background-color: #f7f7f7;
                            border: 1px solid #ddd;
                            border-radius: 999px;
                            color: #666 !important;
                            font-size: 12px !important;
                            display: inline-block;
                            margin: 4px;
                            padding: 12px 30px;
                            text-decoration: none;
                            text-transform: uppercase;
                            text-align:center;

                            &:hover{
                                background-color: #262626;
                                border-color: #262626;
                                color: #f7f7f7 !important;
                            }//HOVER 
                        }//TAB LINK
                    }//MOBILE TAB BUTTON




                }//600

            }//TAB CONTENT

/*-------------------------------------------------TAB WRAPPER MEDIA QUERY (600)*/
            @media (max-width:$lap){
                .activeTab{
                    .mobile_tab_button{
                        a.mobile_tab_link{
                            background-color: #262626;
                            border-color: #262626;
                            color: #f7f7f7 !important;
                        }
                    }//MOBILE TAB BUTTON
                }//ACTIVE LINK
            }//600

        }//TAB WRAPPER



/*FLOAT FIX FOR LAST TAB ITEM---------------------------------------------------*/
        .kstl-carousel-grid-slide>.grid>.grid__item+.grid__item:last-child img{
            float:none;
        }

    }//PROTEIN TABS


/*PROTEIN PEOPLE BLOCK----------------------------------------------------------/
--------------------------------------------------------------------------------*/
    .protein-people-block{
        padding:40px !important;
        background:$transGrey;
        border:1px solid $borderGrey;
        border-radius:20px;

        .cta{
            .cta_image{
                .kstl-image-wrapper{
                    .kstl-responsive-image{
                        img{
 
                        }
                    }//KSTL RESPONSIVE IMAGE
                    @media(max-width:$lap){
                        padding-bottom:0px;
                    }
                }//KSTL IMAGE WRAPPER
            }//CTA IMAGE

            .cta_text{

                .kstl-rich-text{

                    p{
                        @extend %small;
                        padding:10px 0;

                        span.tiny{
                            line-height: 1 !important;
                            padding: 4px 0;
                            display: block;
                        }//SPAN
                    }//P
                }//KSTL RICh TEXT
            }//CTA TEXT
        }//CTA
    }//PROTIEN BLOCK



/*PROTEIN CAROUSEL CONTAINER----------------------------------------------------/
--------------------------------------------------------------------------------*/
.protein-carousel__container{
    padding-top:40px !important;

    .protein-carousel{
            margin:40px -10px !important;
            padding-bottom:40px;

            @media (max-width:$innerBreak){
                    margin:40px -10px !important;
                }//600


/*FLEX VIEWPORT-----------------------------------------------------------------*/
            .flex-viewport{
                max-width: 900px;
                margin: 0 auto;
                //border:1px solid red;

                @media (max-width:$lap){
                    margin:0px 20px;
                }//600

            }//FLEX SLIDER

        
        
/*CAROUSEL----------------------------------------------------------------------*/
        .flexslider{
            z-index:1 !important;
            opacity: .99 !important;
            background:transparent !important;
            display:block;
            //margin:0px 20px;

            @media (max-width:$lap){
                margin:0px;
            }

            ul{
                li{
                    box-sizing: border-box;

                    .kstl-carousel-grid-slide{
                        background:transparent !important;
                        
                        .kstl-image-wrapper{
                            box-sizing: border-box;
                            padding:0px 10px;

                            .kstl-responsive-image {

                                img{
                                    min-width:unset;
                                    width:unset;
                                    margin:0px auto;
                                }//IMG
                            }//KSTL RESPONSIVE IMAGE
                        }

                    }//END SLIDE
                }//END LI
            }//END UL

            .flex-control-nav{
                display:none;
            }


/*-------------------------------------------------------------(side navigation)*/

                ul.flex-direction-nav{
                    //display:block;
                    //position:relative;
                    width:100%;

                    li.flex-nav-next{

                        a{
                            background:url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/nav_arrows.png') no-repeat; 
                            background-position: 0 -36px;
                            height:36px;
                            width:17px;
                        }
                        

                        @media (max-width: $desk){

                        }

                    }//NEXT

                    li.flex-nav-prev{
                        a{
                            background:url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/nav_arrows.png') no-repeat;
                            background-position: 0 0;
                            height:36px;
                            width:17px;
                        }

                        @media (max-width: $desk){

                        }

                    }//PREV
                }//FLEX DIRECTION NAV
        }//END FLEXSLIDER

    }//PROTEIN-CAROUSEL

    p.post-carousel-button{
        text-align:right;
        padding:10px 80px;

        @media (max-width:$innerBreak){
            padding:20px;
            text-align:center;
        }
    }//POST CAROUSEL BUTTON

}//PROTEIN-CAROUSEL__CONTAINER

/*BREAKFAST RECIPES-------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .breakfast-recipes{

        border:1px solid $borderGrey;
        border-radius:20px;
        background:$transGrey;

        .recipe-callout{
            padding:30px !important;
            .callout-text{
                padding:0px 20px;
            }//CALLOUT TEXT

        }//RECIPE CALLOUT


        .recipe-list{
            border-radius:0px 20px 20px 0;
            background:$transGrey;
            padding:30px 20px !important;

            @media (max-width:$innerBreak){
                border-radius:0px 0 20px 20px;
            }
            

            a{
                margin-top:10px !important;
                display:flex;
                align-items: center;
                flex-direction:row;
                flex-wrap: wrap;
                padding-right:10px;
                

                img{
                   display:flex;
                   flex:0 1 auto;
                   width:33%;
                }//IMG

                span{
                    font-weight:bold;
                    display:flex;
                    flex:0 1 auto;
                    width:66%;
                    padding-left:10px;
                    line-height:1;
                }//SPAN


                &:hover{
                    background:$transGrey;
                    cursor:pointer;
                }//HOVER
            }//LINK
        }//RECIPE LIST
    }//BREAKFAST RECIPES


/*SEE YOU AT BREAKFAST----------------------------------------------------------*/
    .see-you{
        margin-top:40px !important;
        padding:20px 40px !important;
        background:$kPurple;
        border-radius:20px;

        .see-you_copy{
            color:$kWhite;

            .button{
                margin-bottom:0px;
            }
        }//SEE YOU COPY

        .see-you_image{
            img{
                display:inline-block;
                float:right;
                width:80% !important;
                margin:0px !important;

                @media(max-width:$tiny){
                    width:100% !important;
                }
            }
        }//SEE YOU IMAGE
    }//SEE YOU


/*KELLOGG'S TO GO---------------------------------------------------------------*/
    .to-go{
        margin-top:40px !important;
        padding:20px 40px !important;
        background:#d11344;
        border-radius:20px;

        .to-go__copy{
            margin-top:40px !important;
            color:$kWhite;

            .button{
                margin-bottom:0px;
            }

            @media(max-width:$innerBreak){
                margin-top:20px !important;
            }//950

            @media(max-width:$lap){
                margin-top:0px !important;
            }//600
        }//COPY

        .to-go__image{
            img{
                display:block;
                width:80% !important;
                margin:0px 0 0 40px !important;

                @media(max-width:$innerBreak){
                    width:60% !important;
                    margin:0px auto !important;
                }

                @media(max-width:$lap){
                    width:100% !important;
                    margin:0px !important;
                }
            }
        }

    }//TO GO

/*HEALTH DETAIL-----------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/