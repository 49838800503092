.heroCarousel{

/*CAROUSEL----------------------------------------------------------------------*/
  .flexslider{
    z-index:1 !important;
    opacity: .99 !important;
    background:transparent !important;
    ul{

      li{
        .kstl-full-image-slide{
          background:transparent !important;
          .kstl-feature-inset{
            background:none;
            width:100%;
            max-width:unset;
            height:100%;
            top:0px;
            bottom:0px;
            left:0px;
            right:0px;
            z-index: 2;
            .kstl-rich-text{
              padding:20px;
            }//END RICH TEXT
          }//END FEATURE INSET
        }//END FULL IMAGE SLIDE
      }//END LI
    }//END UL
  }//END flexslider

/*-----------------------------------------------------------(bottom navigation)*/

ol.flex-control-nav{
  bottom:10px;
  opacity:0.5;

  @media (max-width:$lap){
    opacity:1;
  }//601
}//FLEX CONTROL NAV


/*-------------------------------------------------------------(side navigation)*/

ul.flex-direction-nav{
    .flex-nav-next{ 
        a.flex-next{
            width: 28px;
            height: 64px;
            background: url(/content/dam/NorthAmerica/kelloggs/en_US/images/homepage/hero-right-arrow-grey.png) no-repeat!important;
            right: 0!important;
        }
    }//NEXT

    .flex-nav-prev{ 
        a.flex-prev{
            width: 28px;
            height: 64px;
            background: url(/content/dam/NorthAmerica/kelloggs/en_US/images/homepage/hero-left-arrow-grey.png) no-repeat!important;
            left: 0!important;
        }
    }//PREV
}//FLEX DIRECTION NAV


/*----------------------------------------------------------MEDIA QUERY (MOBILE)*/
@media (max-width: $lap){

/*CAROUSEL----------------------------------------------------------------------*/
      .flexslider{
        ul{
          li{
            .kstl-full-image-slide{
              .kstl-feature-inset{
                margin-top:0px;
                .kstl-rich-text{
                  //padding:0px;


                }//END RICH TEXT
              }//END FEATURE INSET
            }//END FULL IMAGE SLIDE
          }//END LI
        }//END UL
      }//END flexslider

    ol.flex-control-nav{
      display:block;
    }

}//END LAP



/*END HERO CAROUSEL-------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/
