.anchor-articles{
    display:flex !important;
    flex-direction:row;
    flex-wrap: wrap;
    margin:0px -10px;
    padding:0px;


/*GRID ITEM---------------------------------------------------------------------*/
    .grid__item{
        padding:20px 10px 20px 10px;
        display:flex;
        flex: 0 1 auto;

        @media (max-width:$lap){
            padding:5px 10px;
        }
    


/*HEADING (Related Articles)----------------------------------------------------*/

    .kstl-rich-text{
        display:flex;
        width:100%;

        .heading{
            display:block;
            flex:1;
            padding:20px;
            text-align:center;

            h2{
                @extend %extraLarge;
                font-family: KelloggsSansRegular;
            }//H2

            @media (max-width:$lap){
                padding-bottom:0px;

                h2{
                    font-size:30px;
                }//H2

            }//600
            
        }//HEADING
    }//KSTL RICH TEXT
    




/*CTA 2-------------------------------------------------------------------------*/
        .cta_2{
            @extend %clearfix;
            position:relative;
            text-align:center;
            text-decoration:none !important;
            color:$kBlack;
            background:$kWhite;
            padding:0px;
            margin:0px;
            cursor: pointer;
            //border:1px solid blue;


            &:hover{
                box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
            }

            a{
                text-decoration:none !important; 
                width:100%;
            }

/*-----------------------------------------------------------------CTA 2 (image)*/            
            .cta_image{
                display:block;
                width:100%;

                .kstl-image-wrapper{
                    width:100%;
                    img{
                        width:100%;
                    }
                }//KSTL IMAGE WRAPPER

            }//CTA IMAGE

/*------------------------------------------------------------------CTA 2 (text)*/         
            .cta_text{
                text-align: center;
                padding:40px 60px 80px 60px;
                color:$kGrey;

                    h3{
                        font-family: KelloggsSansMedium;
                        font-size: 24px;
                        color:$kBlack;
                    }//H3

                    p{
                        font-family: OpenSans !important;
                        color:$kGrey;
                        font-size: 16px;
                        line-height:1.1;
                    }//p

            }//CTA TEXT

            @media (max-width:$lap){
                margin-top:20px;
            }//600

        }//CTA TYPE TWO





    }//GRID ITEM

/*ANCHOR ARTICLES---------------------------------------------------------------*/    
}                                                                               //
/*------------------------------------------------------------------------------*/