.related-articles{
    margin-top:20px !important;
    //border:1px solid purple;


/*FLEX VIEWPORT-----------------------------------------------------------------*/
    .flex-viewport{
        max-width: 960px;
        margin: 0 auto;
        //border:1px solid red;

        @media (max-width:$lap){
            margin:0px 20px;
        }//600

    }//FLEX SLIDER

    
    
/*CAROUSEL----------------------------------------------------------------------*/
  .flexslider{
    z-index:1 !important;
    opacity: .99 !important;
    background:transparent !important;
    display:block;
    //margin:0px 20px;

    @media (max-width:$lap){
        margin:0px;
    }

    ul{

        li{
            box-sizing: border-box;

            .kstl-full-image-slide{
                background:transparent !important;
                //background:wheat !important;

                .kstl-image-wrapper{
                    box-sizing: border-box;
                    padding:0px 10px;
                }

                .kstl-feature-inset{
                    box-sizing: border-box;
                    padding:0px 10px;
                    position:static;
                    display:block;
                    background:none;
                    width:100%;
                    max-width:unset;
                    height:100%;
                    top:0px;
                    bottom:0px;
                    left:0px;
                    right:0px;

                    .kstl-rich-text{
                        background:$kWhite !important;
                        padding:20px 40px;
                        text-align:center;

                        p.label{
                            text-transform: uppercase;
                            @extend %extraSmall;
                            color: #b51946;
                            margin-bottom: 5px;
                        }//LABEL

                        h4{
                            @extend %medium;
                            color: #2c2c2c;
                            line-height: 1.4;
                            margin-bottom: 20px;
                        }//H4

                        p.body-text{
                            @extend %small;
                            color: #666666;
                            line-height: 1.4;
                            margin: 10px 0;
                        }//BODY TEXT

                        a{
                            display:block;
                            padding:40px 20px;
                            @extend %small;
                            color:$kBlack; 
                        }//A

                    }//END RICH TEXT
                }//END FEATURE INSET
            }//END FULL IMAGE SLIDE
        }//END LI
    }//END UL
  }//END flexslider






/*CAROUSEL MOBILE---------------------------------------------------------------*/
.carousel-mobile{
  .flexslider{
    ul{
        li{
            .kstl-full-image-slide{
                .kstl-image-wrapper{
                    padding:0px 20px;

                    @media (max-width:$lap){
                        padding:0px;
                    }
                    
                }
                .kstl-feature-inset{
                    padding:0px 20px;

                    @media (max-width:$lap){
                        padding:0px;
                    }
                }//END FEATURE INSET
            }//END FULL IMAGE SLIDE
        }//END LI
    }//END UL
  }//END flexslider
}//CAROUSEL MOBILE




/*-----------------------------------------------------------(bottom navigation)*/

ol.flex-control-nav{
  bottom:10px;
  opacity:0.5;

  @media (max-width:$lap){
    opacity:1;
  }//601
}//FLEX CONTROL NAV


/*-------------------------------------------------------------(side navigation)*/

ul.flex-direction-nav{
    //display:block;
    //position:relative;
    width:100%;

    .flex-nav-next{

        @media (max-width: $desk){

        }

    }//NEXT

    .flex-nav-prev{

        @media (max-width: $desk){

        }

    }//PREV
}//FLEX DIRECTION NAV


/*----------------------------------------------------------MEDIA QUERY (MOBILE)*/
@media (max-width: $lap){

/*CAROUSEL----------------------------------------------------------------------*/
      .flexslider{
        ul{
          li{

            .kstl-full-image-slide{


                .kstl-image-wrapper{

                }//KSTL IMAGE WRAPPER

                .kstl-feature-inset{
                    margin-top:0px;

                    .kstl-rich-text{
                    //padding:0px;


                    }//END RICH TEXT
                }//END FEATURE INSET
            }//END FULL IMAGE SLIDE
          }//END LI
        }//END UL
      }//END flexslider

    ol.flex-control-nav{
      display:block;
    }

}//END LAP

/*RELATED ARTICLES--------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/