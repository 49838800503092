/*FULL WIDTH--------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
.fullWidth {
    display: block;
    width:100%;
    max-width:1440px;
    margin: 0 auto;
    z-index: -100;

    .centeredContent {
      display: block;
      max-width: 980px;
      margin: 0 auto!important;

      .breadCrumbs {
        margin-top: 0;

        ul li{
          font-size: 8px;
          font-size: .8rem;
          font-family: KelloggsSansRegular;
          font-weight: 400;
          display: inline-block;

          a{
            font-family: KelloggsSansRegular;
            font-weight: 400;
            &:hover {
              color: #d31145;
              text-decoration: underline;
            }//HOVER
          }//LINK

        }//LI

      }//BREADCRUMBS

/*--------------------------------------------------------------media query(980)*/
    @media (max-width:980px) {
      width: 100%;

      .breadCrumbs {
        width: 100%;
        clear: left;
        margin: 40px 0 0 10px!important;
      }
    }//980

/*--------------------------------------------------------------media query(601)*/
    @media (max-width:601px) {
      margin: 0 auto!important;

      .breadCrumbs {
        margin-top: 10px!important;

        ul{ 
          li {
          font-size: 10px;
          font-size: 1rem;

            a{
              font-size: 10px;
              font-size: 1rem;
              padding: 0;
            }//LINK
          }//LI
        }//UL
      }//BREADCRUMBS
    }//601

  }//CENTERED CONTENT

/*FULL WIDTH--------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/