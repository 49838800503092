/*COLORS------------------------------------------------------------------------*/
$kBlack:#262626;
$kFooter:#696969;
$kWhite:#ffffff;
$kOffWhite:#f7f7f7;
$kGrey:#2c2c2c;
$kLightGrey:#cccccc;
$borderGrey:#ddd;
$kRed:#b41946;
$kBlue:#5d87a1;
$kPurple:#a21983;
$kDarkPurple:#60004d;
$kBurgandy:#6f1b2b;
$kWarmGrey:#47423c;
$kWarmLightGrey:#6c665b;
$transGrey:rgba(204, 204, 204, 0.15);
$transGreyDark:rgba(204, 204, 204, 0.25);


$red:#c01f25;
$brightRed:#d70743;
$copyColor: #3e3e3e;
$linkColor:#c01f25;
$breaksColor:#e8e8e8;
$blue:#17489b;



/*FONT PATH---------------------------------------------------------------------*/
$fontPath: '/etc/designs/kelloggs/kstl/fonts/sans'; 


/*BREAKPOINTS-------------------------------------------------------------------*/
$tiny:350px !default;
$palm:400px !default;
$lap:601px !default;
$tab:800px !default;
$innerBreak:950px !default;
$packBreak:999px !default;
$desk:1080px !default;
$wide:1440px !default;
$tablet:1024px !default;