/*ERROR MESSAGE-----------------------------------------------------------------//
--------------------------------------------------------------------------------*/
#error_wp {
 width:100%;
 text-align: center;
 margin: 0 auto!important;
 position: fixed;
 background: #fff;
 z-index: 999999998!important;
 border-bottom: 1px solid #fff;

 .errorwrap {
   padding: 10px;

   p{
     line-height: 100%;

     a.error_msg {
       @extend %extraTiny;
     }//A
   }//P
 }//ERRORWRAP

 @media (max-width:980px) {
	 width: 100%;
 }//980

/*ERROR_WP----------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/