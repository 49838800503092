.homePage {
	
	.kstl-full-image-slide {
		background: none;
		
		img {
			width: 100%;
		}
		
		@media (max-width:$innerBreak) { 
			.kstl-rich-text {
				padding: 10px 20px 0 5px;
			}
		}
	}

    .kstl-feature-inset { 
		background: none;
		width: 100% !important;
		right: 0;
		bottom: 0;
		top: 10px;
		left: 0;
		z-index: 0 !important;		
		@media (max-width:$innerBreak) {
			background-color: #fff;
			position: static;
		}
		
		/*SLIDE ONE-------------------------------------------------------------*/
       .slideOne{
		    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 72%, rgba(255, 255, 255, 0) 100%);
			margin: 5px 0px 0px calc((100vw / 96) * 1); 
            width: 50%;
			color:$kBlack;
			padding: 30px 10px 40px 80px;
			@media (min-width:$innerBreak) and (max-width:$wide) { 
				margin-top: 0;
				width: 75%;
			}
			
			@media (max-width:$innerBreak) {
				background: none;
				margin-top: 1vw;
				width: auto;
			}

            h1{
                font-family:KelloggsSansMedium;
                font-size:50px;
				line-height:1;
				text-shadow: 0px 0px 22px rgba(255, 255, 255, 1) !important;

				@media (min-width:$innerBreak) and (max-width:$wide) {
					font-size: 3vw;
				}
				@media (max-width:$innerBreak) {
					font-size: 4vw;
				}
				@media (max-width:$lap) {
					font-size: 7.5vw;
				}
            }//h1

            p{
                margin-top:10px;
				line-height:1.1;
				text-shadow: 0px 0px 8px rgba(255, 255, 255, 1) !important;
            }//P

        }//SLIDE THREE*/

    }//KSTL FEATURE INSET*/
	
/*HOMEPAGE----------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/