.recipe-detail{

/*LEFT COLUMN, MOBILE COLUMN----------------------------------------------------*/
    .left-column, .mobile-column{
        //border:1px solid blue;

        .kstl-recipe-servings{
            padding:20px !important;
        } 

/*--------------------------------------------------------LEFT COLUMN (question)*/
        .question{
            border-top:1px solid $kLightGrey;
            border-bottom:1px solid $kLightGrey;
            padding:20px 4px !important;

            a{
                display:flex;
                flex-direction:row;
                text-decoration:none;
                color:$kBlack;

                img{
                    display:flex;
                    height:53px;
                    width:53px;
                }//IMG

                span{
                    display:flex;
                    height:53px;
                    width:auto;
                    padding:19px 0px 0px 10px;
                    font-size:14px;
                    line-height:14px;
                    text-transform:uppercase;
                }//SPAN
            }//LINK
            

            &:hover{
                background:$transGrey;

                a{
                    color:$kRed;
                }//LINK
            }//HOVER

            
        }//QUESTION

/*SHARE-------------------------------------------------------------------------*/
        .share{
            border-bottom:1px solid $kLightGrey;
            padding:20px 4px !important;

            .kstl-add-this{
                display:flex;
                flex-direction:row;

                div{
                    margin-right:0px;
                }//DIV

                .addthis_toolbox{
                    margin-left:0px;
                    display: block;
                    display:flex;
                    width: 53px !important;
                    height: 53px !important;

                    .addthis_button_compact{
                        display: block;
                        text-decoration:none;
                        position: relative;
                        width: 53px !important;
                        height: 53px !important;

                        .at-icon-wrapper{
                            display:none;
                        }//ICON WRAPPER

                        &:after{
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 53px;
                            height: 53px;
                            content: '';
                            background: transparent url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/icon-share.png) 0 0 no-repeat;
                        }//AFTER
                    }//BUTTON COMPACT

                }//ADD THIS TOOLBOX

                .kstl-rich-text{
                    display:flex;
                    height:53px;
                    width:auto;
                    padding:19px 0px 0px 10px;
                    font-size:14px;
                    line-height:14px;
                    text-transform:uppercase;
                }//KSTL RICH TEXT
            }//KSTL ADD THIS

            
            &:hover{
                background:$transGrey;
                cursor:pointer;

                a{
                    color:$kRed;
                }//LINK
            }//HOVER
        }//SHARE
    }//LEFT COLUMN


    @media (max-width:$lap){
        .left-column{
            display:none;
        }//LEFT COLUMN

        .mobile-column{
            display:block;
            margin:20px -20px 0px -20px !important;

            .kstl-recipe-servings{
                padding-left:40px !important;
            } 

            .question, .share{
                padding-left:20px !important;
            }

        }//MOBILE COLUMN
    }//600

/*RIGHT COLUMN------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .right-column{
        //border:1px solid blue;

        .brand-container{
            //border:1px solid green;
        }

        .kstl-sansL{
            display:block;

            .kstl-recipe-name{
                font-size:54px;
                line-height: 100%;
                
                @media (max-width:$lap){
                    font-size:30px;
                    line-height:100%;
                }//600
            }//RECIPE NAME
        }//SANS L

        .kstl-recipe-ingredients{
            padding-top:20px;
        }

        .kstl-recipe-directions {
           padding-top:20px; 
        }


    }//RIGHT COLUMN

/*RELATED RECIPES---------------------------------------------------------------*/
    .related-recipe_heading{
        width:100%;
        text-align:center;

        h3{
            font-size: 36px;
            padding-bottom:20px;

            @media (max-width:$lap){
                font-size:30px;
                line-height:100%;
            }//600

        }//H3

    }//RELATE RECIPE HEADER


    .kstl-related-recipes{
        margin:0px -10px;

        .kstl-related-recipe{
            padding:10px;

            &:hover{
                opacity:.75;
            }


            .related-recipe__img{
                width:100%;
                img{
                    width:100%;
                }//IMG
            }//RELATED IMAGE


            .related-recipe__body{
                //margin:20px 0px;
                padding-bottom:40px;
                text-align:center;

                p.related-recipe__link{
                    padding-top:10px;
                    a{
                        font-weight:bold;
                        color:$kBlack;
                        text-decoration:none;

                        @media (max-width:$lap){

                        }//600
                    }//LINK
                }//P

            }//BODY

        }//RELATED RECIPE
    }//RELATED RECIPES



/*RECIPE DETAIL-----------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/