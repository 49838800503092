.food-landing{

    .with-bg-white{
        margin-top:50px !important;
    }
/*FILTER RESULTS----------------------------------------------------------------*/
    .kstl-search-list{
        padding:40px 0px 20px 0px !important;
    }//SEARCH LIST

    .product_category{

        .result-wrapper__centered{
            display: block;
            padding: 20px;
            text-align: center;
        }


    }

    .result-wrapper{
        display: block;
        padding: 20px;
        text-align: center;
        text-decoration: none;

        h3{
            color : $kBlack;
            text-decoration: none;
            line-height: 100%;
        }

        &:hover{
            h3{
                color: $kRed;
                text-decoration: underline;
            }
        }
    }

    

/*FOOD LANDING------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/
