.breadcrumbs-content{
    //border: 1px solid blue;
    height: 40px;
    padding:0px 20px;
    margin:10px 0px !important;

    ul{
        list-style: none;
        margin-left: 0px;
        height:40px;

        li{
            height:40px;
            position: relative;
            display: inline-block;
            margin-right: 6px;
            vertical-align:middle;
            line-height: 1;
            //border:1px solid red;
            

            &:after{
                content: ">";
                font-family: KelloggsSansMedium;
                font-size:30px;
                display: inline-block;
                position:relative;
                top:4px;
                margin-left:13px;
                opacity: 0.2;
            }//AFTER

            a{
              position: relative;  
              color:$kBlack;
              text-transform:uppercase;
              text-decoration:none;
              @extend %tiny;

              &:hover{
                color:$kRed;
                text-decoration:underline;
              }//HOVER

            }//LINK
        }//LI

/*-----------------------------------------------------------LI (home item LOGO)*/
        li.home-item{
            margin-top:-1px;
            img{
                //border:1px solid red;
                position:relative;
                top:4px;

                &:hover{
                    opacity:.7;
                }//HOVER

            }//IMG

            &:after{
                margin-left:10px;
                top:-2px;
            }//AFTER

        }//HOME ITEM

/*-----------------------------------------------------------LI (current-item)*/
        li.current-item{
            &:after{
                opacity:0;
                top:3px;
            }//AFTER
        }//CURRENT ITEM

        span{
            display: inline-block;
            @extend %tiny;
            font-family: KelloggsSansRegular;
            color: rgb(44, 44, 44);
            text-transform: uppercase;
            text-decoration: none;
        }//SPAN

        @media(max-width:$innerBreak){
            padding:0px; 
        }//LAP

        @media(max-width:$lap){
            display:none;
        }//LAP

    }//UL

    @media(max-width:$lap){
        display:none;
    }//LAP

}//BREADCRUMB WRAPPER