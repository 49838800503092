.article-landing{


/*SEARCH RESULTS----------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .kstl-search-list{
        background:transparent;
        padding:40px 0px 40px 0px !important;
        margin:0px -10px;
        display:flex !important;
        flex-direction:row;
        flex-wrap: wrap;

        @media (max-width:$lap){
            margin:0px;
        }


/*ARTICLE CARD------------------------------------------------------------------*/
        .article-result{
                display:flex;
                flex: 0 1 auto;
                padding:20px 10px;
                background:transparent;
                

                @media (max-width:$lap){
                    padding:0px;
                    margin:20px 0px;
                }

/*----------------------------------------------------------ARTICLE RESULT (card)*/
            a.article-result__card{
                display:block;
                flex:1;
                background:$kWhite;
                font-family: KelloggsSansMedium;
                text-decoration:none;
                color:$kBlack;
                line-height:1.1;
                min-height: 300px;
                

                &:hover{
                    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
                }

                img{
                    width:100%;
                }//img

                h3{
                    padding:10px;
                    text-align:center;
                    text-decoration:none;
                }

                @media (max-width:$innerBreak){
                    min-height: unset;
                }

                @media (max-width:$tiny){
                    padding:0px;
                }//350

            }//ARTICLE CARD
        }//ARTICLE RESULT

    }//KSTL SEARCH LIST


/*ARTICLES----------------------------------------------------------------------*/
}																				//
/*------------------------------------------------------------------------------*/