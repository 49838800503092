.notification{
    background: #fff;
    padding: 26px 20px 20px 20px;
    -webkit-box-shadow: 0px 0px 24px 5px rgba(0,0,0,0.11);
    -moz-box-shadow: 0px 0px 24px 5px rgba(0,0,0,0.11);
    box-shadow: 0px 0px 24px 5px rgba(0,0,0,0.11);

    .notification__close{
        color: $kRed;
        font-weight: bold;
        font-size: 24px;
        right: 6px;
        top: 2px;
    }

    .cookie-warning{
        border: 1px solid $kRed;
        padding:20px;
        

        .inner-cookie{
            

            a{
                color: $kRed;
                font-weight: bold;
                text-decoration: none;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
}
}
