.product-detail{

/*LEFT COLUMN, MOBILE COLUMN----------------------------------------------------//
--------------------------------------------------------------------------------*/
    .left-column, .mobile-column{

        .kstl-product-servings{
            padding:20px !important;
        } 

/*--------------------------------------------------------LEFT COLUMN (question)*/
        .question{
            border-top:1px solid $kLightGrey;
            border-bottom:1px solid $kLightGrey;
            padding:20px 4px !important;

            a{
                display:flex;
                flex-direction:row;
                text-decoration:none;
                color:$kBlack;

                img{
                    display:flex;
                    height:53px;
                    width:53px;
                }//IMG

                span{
                    display:flex;
                    height:53px;
                    width:auto;
                    padding:19px 0px 0px 10px;
                    font-size:14px;
                    line-height:14px;
                    text-transform:uppercase;
                }//SPAN
            }//LINK
            

            &:hover{
                background:$transGrey;

                a{
                    color:$kRed;
                }//LINK
            }//HOVER

            
        }//QUESTION

/*SHARE-------------------------------------------------------------------------*/
        .share{
            border-bottom:1px solid $kLightGrey;
            padding:20px 4px !important;

            .kstl-add-this{
                display:flex;
                flex-direction:row;

                div{
                    margin-right:0px;
                }//DIV

                .addthis_toolbox{
                    margin-left:0px;
                    display: block;
                    display:flex;
                    width: 53px !important;
                    height: 53px !important;

                    .addthis_button_compact{
                        display: block;
                        text-decoration:none;
                        position: relative;
                        width: 53px !important;
                        height: 53px !important;

                        .at-icon-wrapper{
                            display:none;
                        }//ICON WRAPPER

                        &:after{
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 53px;
                            height: 53px;
                            content: '';
                            background: transparent url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/icon-share.png) 0 0 no-repeat;
                        }//AFTER
                    }//BUTTON COMPACT

                }//ADD THIS TOOLBOX

                .kstl-rich-text{
                    display:flex;
                    height:53px;
                    width:auto;
                    padding:19px 0px 0px 10px;
                    font-size:14px;
                    line-height:14px;
                    text-transform:uppercase;

                    &:after{
                        content:'SHARE THIS';
                    }//AFTER
                }//KSTL RICH TEXT

                @media (max-width:$lap){
                    margin:0px;
                }//600

            }//KSTL ADD THIS

            
            &:hover{
                background:$transGrey;
                cursor:pointer;

                a{
                    color:$kRed;
                }//LINK
            }//HOVER
        }//SHARE
    }//LEFT COLUMN

    .mobile-column{
        display:none;
    }

    @media (max-width:$lap){
        .left-column{
            display:none;
        }//LEFT COLUMN

        .mobile-column{
            display:block;
            margin:60px -20px 0px -20px !important;

            .kstl-product-servings{
                padding-left:40px !important;
            } 

            .question, .share{
                padding-left:20px !important;
            }

        }//MOBILE COLUMN
    }//600



/*RIGHT COLUMN------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .right-column{
        padding-left:20px;

        @media (max-width:$lap){
            padding-left: 0px;
        }//600

            


/*-------------------------------------------------------------MOBILE PACK IMAGE*/
        .mobile-pack-image{
            display:none;

/*-------------------------------------------------------MOBILE PACK IMAGE (600)*/          
            @media (max-width:$lap){
                display:block !important;

                .kstl-image-wrapper{
                    width:100%;

                    .kstl-responsive-image{
                        display:block;
                        width:100%;

                        img{
                            display:block;
                            width:80%;
                            margin:0px auto !important;
                        }//IMG

                    }//KSTL RESPONSIVE IMAGE

                }//KSTL IMAGE WRAPPER
            }//600

/*-------------------------------------------------------MOBILE PACK IMAGE (400)*/     
            @media (max-width:$palm){
                .kstl-image-wrapper{
                    .kstl-responsive-image{
                        img{
                            width:100%;
                        }//IMG
                    }//KSTL RESPONSIVE IMAGE
                }//KSTL IMAGE WRAPPER
            }//400

        }//MOBILE PACK IMAGE

/*----------------------------------------------------------------PRODUCT HEADER*/
        h1.kstl-product-header{
            font-size:54px;
            line-height:110%;
            padding-bottom:20px;

            @media (max-width:$tab){
                font-size:40px;
            }//600

            @media (max-width:$lap){
                font-size:30px;
                padding: 0px 0px 20px 0px;
            }//600
        }//PRODUCT HEADER

/*-----------------------------------------------------------PRODUCT DESCRIPTION*/
        p.kstl-product-description{
            @extend %medium;
            padding-bottom:20px;

            @media (max-width:$lap){
                padding: 0px 0px 20px 0px;
            }//600
        }//DESCRIPTION

/*----------------------------------------------------------------NUTRITION INFO*/
        li.kstl-nutrition-info{
            @extend %small;
            padding:0px 0px 10px 0px;

            .kstl-nutrition-header{
                h5{
                    @extend %small;
                    text-transform:uppercase;
                }//H5
            }//NUTRITION HEADER
        }//NUTRITION INFO

/*-------------------------------------------------------PRODUCT NUTRITION LABEL*/
        .nutrition{
            margin-top:20px !important;

            .product_nutrition_label{
                margin:0px;

            }//PRODUCT NUTRITION LABEL

            @media (max-width:$lap){
                margin-top:40px !important;
            }//600

        }//NUTRITION

        .kstl-default-button{
            padding: 15px 30px 12px 30px;
        }//BUTTON
        


    }//RIGHT COLUMN


/*RELATED PRODUCTS--------------------------------------------------------------//
--------------------------------------------------------------------------------*/

    .related-products{

        h4{
            @extend %extraLarge;
            width:100%;
            text-align:center;

            @media (max-width:$lap){
                font-size:30px;
                line-height:100%;
            }//600
        }//H4

        .relatedItem_list{
            margin:0px -20px;

            .grid__item{
                padding:0px 20px;

                &:hover{
                    opacity:.75;
                }

                h3{
                    a{
                        color:$kBlack;
                    }//LINK
                }//H3

                @media (max-width:$lap){
                    width:100%;
                    padding:20px;
                }//600

            }//GRID ITEM

        }//RELATED ITEMS LIST

    }//RELATED PRODUCTS

    
    

/*PRODUCT DETAIL----------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/