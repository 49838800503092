.extraTiny, %extraTiny{
    font-size: 10px;
}

.tiny, %tiny{
    font-size: 12px;
}

.extraSmall, %extraSmall{
    font-size: 14px;
    line-height: 1.2;
}

.small, %small{
    font-size: 16px;
}

.medium, %medium{
    font-size: 18px;
}

.extraMedium, %extraMedium{
    font-size:20px;
}

.large, %large{
    font-size:24px;
}

.extraLarge, %extraLarge{
    font-size:36px;
}

.giant, %giant{
    font-size:56px;
    line-height:1;
}


p.citation{
    @extend %extraSmall;
}


.sup {
  vertical-align:3px !important;
  font-size:90% !important;
}

.larger {
  font-size:12px !important;
  font-size:1.2rem !important;
}