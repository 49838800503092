header{

/*TOP NAV WRAPPER---------------------------------------------------------------*/
    .topNavWrapper{
        background:transparent;

        .grid__item{

           //border:1px solid blue;
        }


/*TOP NAV-----------------------------------------------------------------------*/
        .topNav{
            background:#fff;
			max-width:1440px;
            padding: 10px 20px !important;
            margin:0px auto;


/*NAV-LOGO----------------------------------------------------------------------*/
            .nav-logo{


                .kstl-image-wrapper{
                    display:inline-block;
                    float:left;

                    a{
                        img{
                            min-width:122px;
                        }//IMAGE

                    }//LINK

                }//IMG WRAPPER
            }//NAV LOGO
            

       
/*DESKTOP NAVIGATION------------------------------------------------------------//
--------------------------------------------------------------------------------*/
            .desktop-navigation{
                display:block;
                width:100%;
                //border:1px solid red;

 /*MEGA NAV----------------------------------------------------------------------*/
            .kstl-mega-nav{
				display:block;
                width:100%;
                position: relative;
                z-index: 2;
 
/*-----------------------------------------------------------------MEGA NAV (ul)*/
                ul{
					//border:1px solid orange;
                    display: flex !important;
                    width:100%;
                    margin:0px;
                    //padding:0px 15%;
					padding: 0 10%;

/*HIGHLIGHTED LINK--------------------------------------------------------------*/
                    .highlightedNavLink{
                        color:$kRed !important;
                        a{
                            color:$kRed !important;
                        }

/*-----------------------------------------HIGHLIGHTED LINK (second level links)*/
                        ul{
                            li{
                                a{
                                    color:$kBlack !important;
                                }//LINK
                            }//LI

                            li.highlightedNavLink{
                               a{
                                    color:$kRed !important;

                                    &:hover{
                                        text-decoration:none;
                                    }//HOVEr
                                } 
                            }//HIGHLIGHT
                        }//UL

                    }//HILIGHTED NAVIGATION LINK

/*-----------------------------------------------------------------MEGA NAV (li)*/
                    li.kstl-mega-nav-item--top{
                        //border:1px solid red;
                        position:relative;
                        justify-content: center;
                        text-align:center;
                        padding:0px;
                        margin:0px 10px;

/*------------------------------------------------------------------MEGA NAV (a)*/
                        a{
                            //border:1px solid purple;
                            width: 100%;
                            display: block;
                            font-family: OpenSans;
                            font-weight: 400;
                            color:$kGrey;
                            @extend %small;
                            padding: 10px 0px 10px 0px;
                            
                            &:hover{
                                text-decoration:underline;
                            }//HOVER
                        }//LINK

/*---------------------------------------------------------MEGA NAV (a Dropdown)*/
                        a.js-kstl-mega-nav__link--top{

                            &:after{
                                display:inline-block;
                                font-family: KelloggsSansRegular;
                                font-size:30px;
                                line-height:20px;
                                color:$kLightGrey;
                                content:">";
                                margin:0px 0px 0px 10px;
                                transform-origin:36% 58% 0;
                                transform:rotate(90deg);

                                &:hover{
                                    text-decoration:none !important;
                                }//HOVER

                            }//AFTER

                        }//DROPDOWN

                        
                        a.js-kstl-mega-nav__link--top.open{

                            &:after{
                                display:inline-block;
                                font-family: KelloggsSansRegular;
                                font-size:30px;
                                line-height:20px;
                                color:$kLightGrey;
                                content:">";
                                margin:0px 0px 0px 10px;
                                transform-origin:49% 69% 0;
                                transform:rotate(270deg);

                                &:hover{
                                    text-decoration:none !important;
                                }//HOVER

                            }//AFTER

                        }//DROPDOWN

/*--------------------------------------------------------------MEGA NAV (panel)*/

                        .kstl-mega-nav__panel{
                           min-width:500px;
                           width:auto;
                           padding: 35px 0 0 0px;
                            
                            .kstl-mega-nav-panel--inner{
                                //border:1px solid blue;
                                padding: 0px 30px 30px 30px;


/*-------------------------------------------------MEGA NAV PANEL (close button)*/
                                .kstl-mega-nav-item__link--close{
                                    width:unset;
                                    display:inline-block;
                                }

/*-------------------------------------------------------MEGA NAV PANEL (column)*/
                               ul.kstl-mega-nav__column{
                                    background:#fff;
                                    flex-direction: column;
                                    padding:0px;

                                    li{
                                        text-align:left;
                                        padding:10px;
                                        white-space: nowrap;
                                        padding:0px;

                                        a{
                                            font-size:15px;
                                        }//A
                                    }//LI
                                }//UL

                                ul.kstl-mega-nav__column + ul.kstl-mega-nav__column{
                                    padding-left:20px;
                                }//UL

                                .kstl-mega-nav__feature-image{
                                    display:none;
                                }//IMAGE
                            }//INNER
                        }//PANEL

                    }//MEGA NAV ITEM

                    
/*---------------------------------------------------------UL MEDIA QUERY (1080)*/                    
                    @media (max-width:$desk){
                        padding:0px 10%;
                    }//1080

                }//UL
            }//MEGA NAV
        }//DESKTOP NAVIGATION

            
/*ASK US------------------------------------------------------------------------*/
            .ask-us-overlay-trigger-wrapper{
                display:block;
                padding-top:7px;

                
                a.vbox-item{
                    display:inline-block;
                    float:right;
                    width:75px;
                    height:40px;

                    img{
                        width:100%;
                    }//IMG



                }//VBOX
            }//ASK US


/*SEARCH------------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
            .search-overlay-trigger-wrapper{
                display:block;
                padding-top:14px;

                a.vbox-item{
                    width:34px;
                    height:23px;
                    padding-left:10px;
                    
                    img{
                        width:24px;
                    }//IMg

                    @media(max-width: 999px){
                        float:right;
                        width:24px;
                        padding-left:0px;
                    }//999

                }//LINK
            }//WRAPPER




/*MENU BUTTON-------------------------------------------------------------------*/
            .menu-button {
                //border:1px solid green !important;
                background: #fff;
                cursor: pointer;
                display: block;
                float: right;
                padding: 13px 0 0 0;
                width:28px;

                .navicon {
                    display: inline;

                    span{
                        cursor: pointer;
                        display: block;
                        height: 5px;
                        width: 28px;
                        background: $kRed;

                        &::before{
                            height: 5px;
                            width: 28px;
                            background: $kRed;
                            position: absolute;
                            display: block;
                            content: '';
                            top: -10px;
                        }//BEFORE

                        &::after{
                            height: 5px;
                            width: 28px;
                            background: $kRed;
                            position: absolute;
                            display: block;
                            content: '';
                            bottom: -10px;
                        }//AFTER

                    }//SPAN

                }//NAVICON

            }//MENU BUTTON

/*MOBILE NAVIGATION-------------------------------------------------------------//
            This is only visible when the hamburger icon is pressed.            //
--------------------------------------------------------------------------------*/
        .mobile-navigation{
            .top-menu-container{
                margin:0px -20px;
                padding-top:10px;

                ul.KSTLNav{
                    display:none;
                }

                ul.KSTLNav-show{
                    display:block;

                    li.nav-button{
                        padding-right:0px;
                    }

                    li.button-with-link{
                        border-top:1px solid $kLightGrey;
                        height:auto !important;

                        a{
                            background:#fff;
                            font-family: OpenSans;
                            font-weight: 400;
                            color:$kGrey;
                            font-size:18px !important;
                            padding:15px 20px;

                            &:hover{
                                text-decoration:underline;
                            }//HOVER
                        }//LINK

                    }//LI

                    li.active{
                        //background:$transGrey;

                        a.hasSubnav{
                            color:$kRed;
                            background:$transGrey  !important;

                            span.navicon{
                                &:after{
                                    //content:"264B";

                                }//AFTER
                            }//NAV ICON
                        }//HAS SUBNAV

                        ul.level-two{
                            background:#fff;
                            li.button-with-link{
                                border-top:0px;
                                border-bottom:1px solid $kLightGrey;
                                a{
                                    font-size:15px !important;
                                    padding-left:30px !important;
                                }

                            }//LI
                            
                        }//UL

                    }//ACTIVE

                }//UL
            }//TOP MENU CONTAINER
        }//MOBILE NAVIGATION

            @media (max-width:999px){
                padding: 20px 20px 0px 20px !important;
            }

        }//TOP NAV

/*----------------------------------------------------------------TOP NAV (open)//
--------------------------------------------------------------------------------*/
        .topNav[data-nav="open"] {
                .menu-button {
                    //border:1px solid red;

                    .navicon {
                        position:relative;

                        span {
                            width: 0%;
                            left: 50%;
                            transition-timing-function: cubic-bezier(.55,.055,.675,.19);
                            transition-duration: .22s;

                            &::before{
                                -webkit-transform: rotate(45deg);
                                -moz-transform: rotate(45deg);
                                -o-transform: rotate(45deg);
                                transform: rotate(45deg) !important;
                                transition-timing-function: cubic-bezier(.55,.055,.675,.19);
                                transition-duration: .22s;
                                margin-top:10px;
                            }

                            &::after {
                                bottom:0px;
                                -webkit-transform: rotate(-45deg);
                                -moz-transform: rotate(-45deg);
                                -o-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                                transition-timing-function: cubic-bezier(.55,.055,.675,.19);
                                transition-duration: .22s;
                            }

                        }//SPAN

                    }//NAVICON

                }//MENU BUTTON
            
            }//TOPNAV OPEN

    }//TOPNAV WRAPPER


/*HEADER------------------------------------------------------------------------*/   
}                                                                      //
/*------------------------------------------------------------------------------*/
