
/*LINKS TO A REFERENCE----------------------------------------------------------//
--------------------------------------------------------------------------------*/
a.reference{
    color:$kRed !important;
    &:hover{
        text-decoration:underline;
    }
}//REFERENCE


/*REFERENCE CONTENT-------------------------------------------------------------//
--------------------------------------------------------------------------------*/

.reference-section{
    padding-bottom:20px;

    h2{
        @extend %small;
        padding-bottom:10px;
    }

    .references{
        @extend %extraTiny;
        color:$kRed;
        
        a{
            color:$kBlack;
        }

        @media (max-width:$lap){
            margin-left:10px;
        }//LAP

    }//REFERENCES

    ul.references{
        list-style-type:none;
        margin-left:29px;

        @media (max-width:$lap){
            margin-left:0px;
        }//LAP
    }

    p.references{
        @extend %extraTiny;
        color:$kBlack;
        margin-left:29px;
    }
}//REFERENCE SECTION


