/*BUTTONS-----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
input[type="button"], input[type="reset"], input[type="submit"], button, .button, %button{

/*-------------------------(this "not" class makes it not affect editing in AEM)*/
    &:not(.x-btn-text){
        -moz-appearance: none;
        border:0px;
        cursor:pointer;
        display:inline-block;
        font-family:KelloggsSansRegular;
        font-weight:400;
        font-size:14px !important;
        color:$kWhite;
        line-height:100%;
        margin-bottom:1em;
        padding:15px 30px !important;
        text-decoration: none;
        transition: all 200ms ease 0s;
        vertical-align: middle;
        white-space: nowrap;
        text-transform:uppercase;
        @include border-radius(999px);
    }
	
}

.burgundy{
	background:$kBurgandy !important;
	&:hover{
		color:$kBurgandy !important;
		background:$kWhite !important;
		text-decoration:none;
	}
}

.red{
	background:$kRed !important;
	&:hover{
		background:$kBurgandy !important;
		text-decoration:none;
	}
}

.white{
    background-color: $kWhite;
    color: $kGrey !important;
    border: solid 1px $kLightGrey;
    &:hover {
        background-color: $kGrey;
        color: white !important;
    }
}

.off-white{
    background-color: $kOffWhite;
    color: $kGrey !important;
    border: solid 1px $kLightGrey !important;
    &:hover {
        background-color: $kGrey;
        border: solid 1px $kGrey !important;
        color: $kOffWhite !important;
    }
}

.purple{
    background-color:$kDarkPurple;
    color: $kOffWhite !important;
    border: solid 1px $kDarkPurple !important;
    &:hover {
        background-color: $kOffWhite;
        color: $kDarkPurple !important;
    }
}




.desk-two-tenths.white,
.lap-two-thirds.white,
.palm-two-thirds.white {
    display: none;
}


/*VIEW ALL----------------------------------------------------------------------*/
.view-all{
    width:100%;
    padding-top:20px !important;
    text-align:center;

    .view-all_link{
        color:$kBlack;
        padding-bottom: 2px;
        border-bottom: 2px solid $kBlack;
        font-family: KelloggsSansMedium;
        text-transform:uppercase;
        text-decoration:none;

        &:hover{
            color:$kRed;
            border-bottom: 2px solid $kRed;
        }
    }//VIEW ALL LINK
}//VIEW ALL


/*KSTL DEFAULT BUTTON-----------------------------------------------------------*/
.kstl-default-button, %kstl-default-button{
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 999px;
    color: #666;
    font-size: 12px;
    display: inline-block;
    margin: 4px;
    padding: 12px 50px 12px 30px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover{
        background-color: #262626;
        border-color: #262626;
        color: #f7f7f7;
    }//HOVER
}//KSTL DEFAULT BUTTON


/*SHARE BUTTON------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
        .share-button{
            width:172px;
            border-radius:10px;
            margin:40px auto !important;
            padding:6px 10px !important;

            .kstl-add-this{
                display:flex;
                flex-direction:row;

                div{
                    margin-right:0px;
                }//DIV

                .addthis_toolbox{
                    margin-left:0px;
                    display: block;
                    display:flex;
                    width: 53px !important;
                    height: 53px !important;

                    .addthis_button_compact{
                        display: block;
                        text-decoration:none;
                        position: relative;
                        width: 53px !important;
                        height: 53px !important;

                        .at-icon-wrapper{
                            display:none;
                        }//ICON WRAPPER

                        &:after{
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 53px;
                            height: 53px;
                            content: '';
                            background: transparent url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/icon-share.png) 0 0 no-repeat;
                        }//AFTER
                    }//BUTTON COMPACT

                }//ADD THIS TOOLBOX

                .kstl-rich-text{
                    display:flex;
                    height:53px;
                    width:auto;
                    padding:19px 0px 0px 10px;
                    font-size:14px;
                    line-height:14px;
                    text-transform:uppercase;
                }//KSTL RICH TEXT
            }//KSTL ADD THIS

            
            &:hover{
                background:$transGrey;
                cursor:pointer;

                a{
                    color:$kRed;
                }//LINK
            }//HOVER
        }//SHARE

/*CATEGORY BUTTON---------------------------------------------------------------//
--------------------------------------------------------------------------------*/

.category-button{
    @extend %extraSmall;
    display:block;
    width:300px;
    border-radius:40px;
    margin:0px auto !important;
    padding:10px 30px 10px 40px !important;
    background:$transGrey;
    line-height:1;
    color:$kBlack;
    cursor:pointer;

    span{
        font-weight:bold;
        line-height:1.2;
    }//SPAN

    &:hover{
        background:$kGrey;
        color:$kWhite;
        cursor:pointer;
    }//HOVER

    @media (max-width:$innerBreak){
        margin:10px auto !important;
    }

    @media (max-width:$lap){
        max-width:350px;
        width:100%;
        margin:0px auto !important;
    }

}//CATEGORY BUTTON

/*EXTERNAL----------------------------------------------------------------------*/
a.external{
    color:$kRed !important;
    
    &:hover{
        text-decoration:underline;
    }//HOBER

    &:after{
        display:inline-block;
        width: 13px;
        height:13px;
        content:'';
        background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/arrow.png) no-repeat;
        background-size:10px 10px;
        background-position: 2px 3px;
    }
}//LINK