.our-story{
		
	.your-questions-answered {
		.kstl-full-image-slide {
			background: none;
			
			img {
				width: 100%;
			}
			
			.kstl-feature-inset {
				display: none !important;
			}
			
			@media (max-width:$innerBreak) { 
				.kstl-rich-text {
					padding: 10px 20px 0 5px;
				} 
			}
		}
    }
    
/*OUR VALUES--------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
.our-values{
    
    .kstl-feature-inset{
	
/*SLIDE TWO---------------------------------------------------------------------*/
        .slideCopy{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:610px;
            color:$kWhite;
            text-shadow: 0px 0px 26px rgba(0, 0, 0, 1);

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*---------------------------------------------------SLIDE TWO MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            width: 350px;
        }//950
            
/*---------------------------------------------------SLIDE TWO MEDIA QUERY (601)*/
            @media (max-width:$lap){
                color:$kBlack;
                text-shadow: none;
                width:90%;
                margin:0px auto 10px auto;
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE TWO


        @media (max-width:$innerBreak){
            position: absolute;
        }//950

        @media (max-width:$lap){
            position: static;
        }//950

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV
}//OUR VALUES

/*OUR WORKPLACE-----------------------------------------------------------------//
                                    carousel                                    //
--------------------------------------------------------------------------------*/
.our-workplace{

    .kstl-feature-inset{
	
/*SLIDE TWO---------------------------------------------------------------------*/
        .slideCopy{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:500px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*---------------------------------------------------SLIDE TWO MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            width: 350px;
        }//950
            
/*---------------------------------------------------SLIDE TWO MEDIA QUERY (601)*/
            @media (max-width:$lap){
                color:$kBlack;
                width:90%;
                margin:0px auto 10px auto;
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE TWO


        @media (max-width:$innerBreak){
            position: absolute;
        }//950

        @media (max-width:$lap){
            position: static;
        }//950

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV
}//OUR WORKPLACE


/*OUR COMMUNITY-----------------------------------------------------------------//
                            Feeding people in need                              //
--------------------------------------------------------------------------------*/
.our-community{

    .kstl-feature-inset{

/*SLIDE ONE---------------------------------------------------------------------*/
        .slideOne{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:600px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*---------------------------------------------------SLIDE ONE MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            width:350px;
        }//950
            
/*---------------------------------------------------SLIDE ONE MEDIA QUERY (601)*/
            @media (max-width:$lap){
                color:$kBlack;
                width:90%;
                margin:0px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE ONE

        
        @media (max-width:$innerBreak){
            position: absolute;
        }//950

        @media (max-width:$lap){
            position: static;
        }//950

        

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV
}//OUR COMMUNITY


/*MARKETPLACE COMMITMENT--------------------------------------------------------//
                            Nourishing with our foods                           //
--------------------------------------------------------------------------------*/
.marketplace-commitment{

    .kstl-feature-inset{

/*SLIDE FOUR--------------------------------------------------------------------*/
        .slideFour{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:610px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*--------------------------------------------------SLIDE FOUR MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            width: 350px;
        }//950
            
/*--------------------------------------------------SLIDE FOUR MEDIA QUERY (601)*/
            @media (max-width:$lap){
                color:$kBlack;
                width:90%;
                margin:0px auto 10px auto;
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE FOUR


        @media (max-width:$innerBreak){
            position:absolute;
        }//950

        @media (max-width:$lap){
            position:static;
        }//950

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV
}//MARETPLACE COMMITMENT


/*OUR LOCATIONS-----------------------------------------------------------------//
                                    carousel                                    //
--------------------------------------------------------------------------------*/
.our-locations{

    .kstl-feature-inset{

/*SLIDE ONE---------------------------------------------------------------------*/
        .slideOne{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:500px;
            color:$kBlack;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*---------------------------------------------------SLIDE ONE MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            color:$kBlack;
            margin:0px 0px 0px calc((100vw / 95) * 2);
        }//950
            
/*---------------------------------------------------SLIDE ONE MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE ONE



/*SLIDE TWO---------------------------------------------------------------------*/
        .slideTwo{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:500px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*---------------------------------------------------SLIDE TWO MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            color:$kBlack;
            margin:0px 0px 0px calc((100vw / 95) * 2);
        }//950
            
/*---------------------------------------------------SLIDE TWO MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE TWO

        
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV
}//OUR LOCATIONS



/*ENVIRONMENTAL INITIATIVES-----------------------------------------------------//
                            Nurturing Our Planet                                //
--------------------------------------------------------------------------------*/
.environmental-initatives{

    .kstl-feature-inset{


/*SLIDE FOUR--------------------------------------------------------------------*/
        .nuture{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:610px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*--------------------------------------------------SLIDE FOUR MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            width: 350px;
        }//950
            
/*--------------------------------------------------SLIDE FOUR MEDIA QUERY (601)*/
            @media (max-width:$lap){
                color:$kBlack;
                width:90%;
                margin:0px auto 10px auto;
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE FOUR

    @media (max-width:$innerBreak){
            position:absolute;
        }//950

        
        @media (max-width:$lap){
            position:static;
        }//950

    }//KSTL FEATURE INSET


    .flex-control-nav{
        @media (max-width:$innerBreak){
            position:static;
        }//950

    }//FLEX CONTROL NAV
}//ENVIRONMENTAL INITIATIVES



.narrow{
    padding-left:40px;

    @media (max-width:$lap){
        padding-left:0px;
    }//950
}//NARROW


/*OUR STORY---------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/