.corporate-page{
    display:block;



/*WHITE BACKGROUND--------------------------------------------------------------*/
    .white-bg{
        background:#fff;
        display:block;

        h1{

        }

        h2{
            margin-top:20px;
        }

/*------------------------------------------------------------------UPDATED DATE*/
        p.updated{
            @extend %tiny;
        }//UPDATED

/*---------------------------------------------------------------------PARAGRAPH*/
        p{
            margin-top:20px;
        }//P

/*--------------------------------------------------------------FIRST LEVEL LIST*/
        ol.roman{
            margin-top:20px;
            list-style-type:upper-roman;
            font-family: KelloggsSansRegular !important;

            li{
                margin-top:10px;
                font-family: KelloggsSansRegular !important;
                @extend %medium;

                p{
                    font-family: OpenSans !important;
                    @extend %small;
                }//P

/*-------------------------------------------------------------SECOND LEVEL LIST*/
                ol{
                    list-style-type:lower-alpha;

                    li{

/*--------------------------------------------------------------THIRD LEVEL LIST*/                  
                        ul{
                            list-style-type: circle;
                        }//THIRD LEVEL UNORDERED LIST

                    }//SECOND LEVEL LI
                }//SECOND LEVEL OL

            }//LI
            
        }//ROMAN

    }//WHITE BG
    

/*-----------------------------------------(torn border at bottom of white area)*/
    &:after{
        content: "";
        display: block;
        width: 100%;
        height: 50px;
        background: transparent url(/content/dam/Australia/kelloggs_au/images/wave-bottom-white.png) 50% 100% no-repeat;
    }//AFTER CORPORATE PAGE


/*SITEMAP-----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .sitemap{

/*------------------------------------------------------------SITEMAP (subtitle)*/
        h2.subtitle{
            @extend %medium;
            padding-bottom:10px;
        }

/*----------------------------------------------------------------SITEMAP (list)*/
        ul{
            li{
                a{
                    color:$kRed;
                    text-decoration:none;
                    &:hover{
                        text-decoration:underline;
                    }//HOVER
                }//LINK
            }//LI
        }//UL
    }//SITEMAP



/*CORPORATE PAGE----------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/