@font-face {
    font-family: OpenSans;
    src: url(/content/dam/NorthAmerica/kelloggsca/en_CA/fonts/opensans-light-webfont.woff) format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: OpenSans;
    src: url(/content/dam/NorthAmerica/kelloggsca/en_CA/fonts/opensans-regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: OpenSans;
    src: url(/content/dam/NorthAmerica/kelloggsca/en_CA/fonts/opensans-semibold-webfont.woff) format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: OpenSans;
    src: url(/content/dam/NorthAmerica/kelloggsca/en_CA/fonts/opensans-bold-webfont.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: OpenSans;
    src: url(/content/dam/NorthAmerica/kelloggsca/en_CA/fonts/opensans-extrabold-webfont.woff) format("woff");
    font-weight: 800;
    font-style: normal;
}

/*KELLOGGS FONT-----------------------------------------------------------------*/
@font-face {
    font-family: KelloggsSansLight;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-Light.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-Light.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-Light.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-Light.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-Light.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansLightItalic;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-LightItalic.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-LightItalic.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-LightItalic.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-LightItalic.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/light/KelloggsSans-LightItalic.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansRegular;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Regular.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Regular.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Regular.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Regular.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Regular.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansItalic;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Italic.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Italic.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Italic.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Italic.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/regular/KelloggsSans-Italic.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansMedium;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-Medium.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-Medium.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-Medium.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-Medium.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-Medium.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansMediumItalic;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-MediumItalic.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-MediumItalic.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-MediumItalic.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-MediumItalic.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/medium/KelloggsSans-MediumItalic.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansBold;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-Bold.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-Bold.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-Bold.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-Bold.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-Bold.svg) format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: KelloggsSansBoldItalic;
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-BoldItalic.eot);
    src: url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-BoldItalic.eot?#iefix) format("embedded-opentype"), url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-BoldItalic.woff) format("woff"), url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-BoldItalic.ttf) format("truetype"), url(/etc/designs/kelloggs/kstl/fonts/sans/bold/KelloggsSans-BoldItalic.svg) format("svg");
    font-weight: 400;
    font-style: normal
}



/*NUTRITION FONTS---------------------------------------------------------------*/
@font-face {
  font-family: 'DINBlack';
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Black.eot'); /* IE9 Compat Modes */
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Black.woff') format('woff'), /* Modern Browsers */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Black.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Black.svg#656984feefe046b1101270f2fac7fccc') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'DINBold';
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Bold.eot'); /* IE9 Compat Modes */
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Bold.woff') format('woff'), /* Modern Browsers */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Bold.svg#141abd1e4da862069abc8898071740b0') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'DINLight';
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Light.eot'); /* IE9 Compat Modes */
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Light.woff') format('woff'), /* Modern Browsers */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Light.svg#2027e760254904bf0a383b6efd34e0f4') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'DINMedium';
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Medium.eot'); /* IE9 Compat Modes */
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Medium.woff') format('woff'), /* Modern Browsers */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Medium.svg#d810fd0de5f94c19f1c61300cb3051eb') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'DINRegular';
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Regular.eot'); /* IE9 Compat Modes */
  src: url('/content/dam/Australia/nutrigrain/fonts/DIN-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Regular.woff') format('woff'), /* Modern Browsers */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/content/dam/Australia/nutrigrain/fonts/DIN-Regular.svg#7ce5c290cbf96055cac52fb25e35bed6') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Tahoma';
  src: url('/content/dam/Australia/nutrigrain/fonts/Tahoma Regular.eot'); /* IE9 Compat Modes */
  src: url('/content/dam/Australia/nutrigrain/fonts/Tahoma Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/content/dam/Australia/nutrigrain/fonts/Tahoma Regular.woff') format('woff'), /* Modern Browsers */
       url('/content/dam/Australia/nutrigrain/fonts/Tahoma Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/content/dam/Australia/nutrigrain/fonts/Tahoma Regular.svg#2bd141ae2a8e92e3cdd9163089ec8924') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}