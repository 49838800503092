.cereal-and-milk{

/*HERO BANNER-------------------------------------------------------------------*/
    .hero-banner{
        .description{
            width:40% !important;
            //border:1px solid red;
            position:relative;
            margin:60px 0px 0px 34% !important;

            p{
              font-size:24px !important;
              color:$kBlue;
            }

            p.tiny{
                position:absolute;
                bottom:0px;
                font-size:12px !important;
            }

            @media (max-width:$innerBreak){
                width:100% !important;
                margin:20px auto !important;
            }//980
        }//DESCRIPTION
    }//HERO BANNER


/*CEREAL GRID-------------------------------------------------------------------//
--------------------------------------------------------------------------------*/

    .cereal-grid{
        width:100%;
        max-width:950px;

        .half-cereal{
            margin:0px !important;

            .grid{
                .grid__item{
                    //padding:5px !important;
                }//GRID ITEM
            }//GRID

            .quarter-grid{
                //border:1px solid blue;                
            }//QUARTER GRID
        }//HALF CEREAL


/*HEIGHTS-----------------------------------------------------------------------//
            These should apply to all of the tiles on this page.                //
--------------------------------------------------------------------------------*/
        .full-height{
            margin:5px !important;
            height:610px;

            @media (max-width:$packBreak){
                height:calc((100vw /  5) *  6);
            }//998

            @media(max-width:$lap){
                height:unset;
                //height:1505px;
            }//600

        }//FULL HEIGHT


        .two-thirds-height{
            margin:5px !important;
            height:400px;

            @media (max-width:$packBreak){
                height:calc((100vw /  5) *  4);
            }//998

            @media(max-width:$lap){
                height:unset;
            }//600

        }//TWO THIRDS HEIGHT

        .one-third-height{
            margin:5px !important;
            height:200px;

            @media (max-width:$packBreak){
                height:calc((100vw /  5) *  2);
            }//998

            @media(max-width:$lap){
                height:unset;
            }//600

        }//ONE THIRD HEIGHT

        .three-quarter-height{
            margin:5px !important;
            height:450px;

            @media (max-width:$packBreak){
                height:calc((100vw /  10) *  9);
            }//998

            @media(max-width:$lap){
                height:unset;
            }//600

        }//THREE QUARTER


        .quarter-height{
            margin:5px !important;
            height:150px;

            @media (max-width:$packBreak){
                height:calc((100vw /  10) *  3);
            }//998

            @media(max-width:$lap){
                height:unset;
            }//600

        }//QUARTER HEIGHT


/*HORIZONTAL HEIGHTS------------------------------------------------------------//
--------------------------------------------------------------------------------*/

        .two-fifths-height{
            margin:5px !important;
            height:235px;


            @media(max-width:$lap){
                height:unset;
            }//600

        }//TWO FIFTHS


        .one-fifth-height{
            margin:5px !important;
            height:120px;

            @media(max-width:$lap){
                height:unset;
            }//600

        }//ONE FIFTH


        .thirds-height{
            margin:5px !important;

            @media (max-width:$packBreak){
                height:393px;
                height:calc((100vw /  1000) *  393);
            }//998

            @media(max-width:$lap){
                height:unset;
            }//600
        }//ONE THIRD HEIGHT



/*-------------------------------------------------------------FULL IMAGE SLIDES*/
        .kstl-full-image-slide{
            position:relative;

            .kstl-image-wrapper{
                height:610px;

                @media (max-width:$packBreak){
                    height:calc((100vw /  100) *  61);
                    height:calc((100vw /  50) *  61);
                    height:calc((100vw /  100) *  120);
                }//998

                @media(max-width:$lap){
                        height:unset;
                        //height:1505px;
                    }

                img{
                    height:610px;

                    @media (max-width:$packBreak){
                        height:calc((100vw /  100) *  61);
                        height:calc((100vw /  50) *  61);
                        height:calc((100vw /  5) *  6);
                    }//998

                    @media(max-width:$lap){
                        height:unset;
                        //height:1505px;
                    }
                }//IMG
            }//KSTL IMGE WRAPPER

            .kstl-feature-inset{
                //border:1px solid red;
                background:transparent;
                width:100%;
                top:0px;
                right:0px;
                bottom:0px;
                left:0px;

                .kstl-rich-text{
                    color:$kBlue !important;
                    .copy{
                        //border:1px solid red;
                        margin-top:250px;

                        a{
                            color:$kBlue !important;
                            text-decoration:none;

                            h2{
                                @extend %large;
                                font-family: KelloggsSansMedium;
                                line-height:1;
                            }//H2

                            p{
                                @extend %extraSmall;
                                line-height:1.4;
                                margin-top:10px;
                            }//P
                        }//LINK
                    }//COPY

                    .image{
                        width:100%;
                        position:absolute;
                        bottom:0px;
                        img{
                            width:100%;
                        }
                    }//IMAGE

                }//KSTL RICH TEXT

                &:hover{
                    .kstl-rich-text{
                        .image{
                            background:url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/cereal-and-milk/rkCereal_glow.png') no-repeat;
                            background-size:100% 100%;
                        }//IMAGE
                    }//KSTL RICH TEXT
                }//HOVER

/*-----------------------------------------------------------FEATURE INSET (999)*/
                @media (max-width: $packBreak){
                     .kstl-rich-text{
                        .copy{
                            margin-top:600px;
                            margin-top:calc((100vw / 499) * 260);

                            a{
                                h2{
                                font-size:32px !important;
                            }//H2
                            p{
                                font-size:20px !important;
                                line-height:1 !important;
                            }//P
                            }//LINK
                        }//COPY
                    }//KSTL RICH TEXT


                }//998

/*-----------------------------------------------------------FEATURE INSET (600)*/
                @media (max-width:$lap){
                    position:absolute;
                    top:0px;
                    right:0px;
                    bottom:0px;
                    left:0px;
                    display:flex;
                    flex-direction:column;
                    align-items: center;
                    justify-content: center;

                    .kstl-rich-text{
                        .copy{
                            margin-top:50px;
                            p{
                                line-height:1 !important;
                            }//P
                        }//COPY
                    }//KSTL rich TEXT

                    .image{
                        left:0px;
                        line-height:0px;
                        a{
                            padding:0px !important;
                        }//LINK
                    }//IMAGE
                }//600

            }//KSTL FEATURE INSET

            @media (max-width:$lap){
                .kstl-image-wrapper{
                    padding-bottom:0px;
                }
            }


        }//KSTL-FULL-IMAGE-SLIDE


/*--------------------------------------------------------------------RASIN BRAN*/
        .raisin-bran{

        }//RAISIN BRAN


/*------------------------------------------------------------BEAUTIFULLY SIMPLE//
--------------------------------------------------------------------------------*/
        .beautifully-simple{
            background:$kBlue;
            padding:60px 10px !important;
            display:flex;
            flex-direction:column;
            align-items: center;
            justify-content: center;

            .kstl-image-wrapper{
                width:100%;

                img{
                    display:block;
                    margin:0px auto !important;
                }
            }//IMAGE WRAPPER

            .copy{
                color:$kWhite;
                text-align:center;
                padding-top:10px !important;

                p{
                    @extend %large;
                }//P

                p.sans{
                    @extend %medium;
                    font-family: OpenSans;
                }//SANS
                

            }//COPY

        }//BEAUTIFULLY SIMPLE


/*--------------------------------------------------------------------CHECKBOXES//
--------------------------------------------------------------------------------*/
        .checkboxes{
            border:5px solid $kBlue;
            background:$kWhite;
            padding:20px 20px 30px 20px !important;
            color:$kBlue;
            font-family: KelloggsSansMedium;

            .check__copy{
                p.check__boxes{
                    display:flex;
                    flex-direction:row;
                    flex-wrap: wrap;
                    padding-top:10px !important;

                    img{
                        display:flex;
                        flex:0 1 auto;
                        width:20px;
                        height:20px;
                    }//IMG

                    span{
                        display:flex;
                        flex:0 1 auto;
                        font-family: KelloggsSansBold;
                        @extend %medium;
                        padding-left: 10px;
                        height:20px;
                        line-height:1;
                        align-items: center;
                        justify-content: center;
                    }//SPAN
                }//BOXES

                p.check__text{
                    line-height:1;
                    margin-top:10px !important;
                }//CHECK TEXT
            }//CHECK COPY

        }//CHECKBOXES


/*------------------------------------------------------------------SOUNDS GREAT//
--------------------------------------------------------------------------------*/
        .sounds-great{
            color:$kBlue;
            background:url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/cereal-and-milk/sounds_good_rk_bg.jpg') no-repeat;
            background-size:100% 100%;
            display:flex;
            flex-direction: column;

            .grid__item{
                display:flex;
                flex:1;

                .kstl-rich-text{
                    display:flex;
                    flex:1;

                    .inner{
                        display:flex;
                        flex:1;
                        flex-direction: column;
                        position:relative;
                        background:url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/cereal-and-milk/rkCereal-small.png??') right bottom no-repeat;

                        h2{
                            display:block;
                            width:100%;
                            padding:4px 10px 0px 10px !important;
                            @extend %large;
                        }//H2

                        p{
                            width:100%;
                            padding:0px 10px !important;
                            @extend %small;
                            line-height:1;
                        }//P
                    }//INTERIOR
                }//KSTL RICH TEXT
            }//GRID ITEMS
            

            &:hover{
                cursor:pointer;
                .grid__item{
                    display:flex;
                    flex:1;

                    .kstl-rich-text{
                        display:flex;
                        flex:1;

                        .inner{
                            background:
                            url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/cereal-and-milk/rkCereal-small.png')no-repeat, 
                            url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/cereal-and-milk/rkCereal_glow.png') no-repeat;

                            background-position:right bottom, right bottom;
                        }//INNER
                    }//KSTL RICH TEXT
                }//GRID ITEM
            }//HOVER

/*-------------------------------------------------------------SOUNDS GREAT(999)*/
            @media (max-width:$packBreak){
                //min-height:300px;
                .grid__item{
                    .kstl-rich-text{
                        .inner{
                            h2{
                                font-size: 30px;
                            }//H2

                            p{
                                font-size: 20px;
                            }//P
                        }//INNER
                    }//KSTL RICH TEXT
                }//GRID ITEM
            }//999

/*-------------------------------------------------------------SOUNDS GREAT(600)*/
            @media (max-width:$lap){
                min-height:300px;
                .grid__item{
                    .kstl-rich-text{
                        .inner{
                            h2{
                                font-size: 30px;
                            }//H2

                            p{
                                font-size: 24px;
                            }//P
                        }//INNER
                    }//KSTL RICH TEXT
                }//GRID ITEM
            }//600

        }//SOUNDS GREAT


/*---------------------------------------------------------SIMPLE FROM THE START//
--------------------------------------------------------------------------------*/
        .simple-start{
            position:relative;
            border:5px solid $kRed;
            background:$kRed;
            color:$kWhite;
            overflow:hidden;
            padding:20px 10px 10px 10px !important;
            cursor:pointer;
            display:flex;
            align-items: center;
            justify-content: center;

            .wrapper{
                display:flex;

                .show{
                    position:absolute;
                    transition: top 0.2s ease;
                    top:100%;
                    right:0px;
                    bottom:0px;
                    left:0px;
                    background:$kWhite;
                    color:$kRed;
                    padding:20px;
                    ol{
                        @extend %tiny;
                        margin-left:20px;
                        li{
                            padding-top:6px;
                        }//li
                    }//REFERENCES
                }//SHOW

                .hide{

                    h2{
                        font-size:30px;
                        font-family: KelloggsSansBold;
                        line-height:1;
                    }//H2

                    p.body{
                        margin-top:10px !important;
                        @extend %extraSmall;
                        line-height:1.5;
                    }//P

                    img{
                        display:block;
                        margin:10px auto !important;
                    }//IMG
                }//HIDE

                p.sources{
                    @extend %tiny;
                    line-height:1;
                    font-family: KelloggsSansMedium;
                    align-self: baseline; 
                }//SOURCE
            }//WRAPPER

            &:hover{
                .show{
                    top:0px;
                }//SHOW

                .hide{
                    display:none;
                }//HIDE
            }//HOVER

            @media (max-width:$packBreak){
                .wrapper{
                    .hide{
                        position:relative;
                        height:calc((100vw / 5) * 3);

                        img{
                            position:absolute;
                            top:50%;
                            left:45%;
                        }//IMG
                    

                        p.sources{
                            position:absolute;
                            bottom:0px;
                        }//SOURCE
                    }//HIDE
                }//WRAPPER
            }//999

            @media (max-width:$lap){
                .wrapper{
                    min-height:300px;
                    .hide{
                        position:relative;
                        height:unset;
                        
                        img{
                            position:static;
                            margin:40px auto !important;
                        }//IMG
                    
                        p.sources{
                            position:absolute;
                            bottom:0px;
                        }//SOURCE
                    }//HIDE
                }//WRAPPER
            }//600
        }//SIMPLE

/*-----------------------------------------------------------------------PROTEIN//
--------------------------------------------------------------------------------*/
        .protein{
            border:5px solid $kBlue;
            background:$kWhite;
            padding:20px 10px 30px 10px !important;
            color:$kBlue;
            text-align:center;
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content: center;

            .grid__item{
                display:flex;
                flex-direction:row;
                align-items: center;
                justify-content: center;

                .interior{

                    p{
                        @extend %medium;
                        //line-height:1;
                    }//P

                    img{
                        margin:20px auto !important;
                    }//IMG
                }//INTERIOR
            }//GRID ITEM

            @media (max-width:$packBreak){
                .grid__item{
                        height:500px;
                    .interior{
                        p{
                            font-size:24px;
                        }//P
                    }//INTERIOR
                }//GRID ITEM
            }//999
        }//PROTEIN



/*--------------------------------------------------------MILK EVERY OPPERTUNITY//
--------------------------------------------------------------------------------*/
        .milk-it{
            background:$kRed;
            position:relative;

            .kstl-image-wrapper{
                width:100%;
                position:absolute;
                bottom:0px;

                img{
                    width:100%;
                }

                @media (max-width:$lap){
                    position:static;
                    padding:0px !important;
                }
            }//IMAGE WRAPPER
        }//MILK IT



/*----------------------------------------------------------------THE DAY AWAITS//
--------------------------------------------------------------------------------*/
        .flex-flakes{
            background:$kBlue;
            display:flex;
            flex-direction:row;
            flex-wrap: wrap;


            .grid__item{
                display:flex;
                flex:1 1 auto;
            }

            .the-day-awaits{
                display:flex;
                flex:0 1 auto;
                margin:0px !important;
                color:$kWhite;
                padding:20px 5px !important;
                text-align:center;
                border-right:5px solid $kWhite;

                h2{
                    @extend %extraMedium;
                }

                p{
                    padding-top:10px;
                    @extend %extraSmall;
                }

            }//THE DAY AWAITS


            .flakes{
                display:flex;
                flex:0 1 auto;
                margin:auto !important;
                padding:0px !important;
                line-height:0px;

                .kstl-image-wrapper{
                    width:100%;
                    background:$kWhite;
                    padding:0px;
                    img{
                        width:100%;
                    }
                }
            }//FLAKES

            @media (max-width:$packBreak){
                .the-day-awaits{
                    align-items: center; 
                    justify-content: center;                    
                    width:100%;

                    .kstl-rich-text{
                        width:100%;
                    }//KSTL rICH TEXT
                }//THE DAY AWAITS

                .flakes{
                    width:70%;
                    .kstl-image-wrapper{
                        width:100%;
                        img{
                            width:100%;
                        }
                    }
                }//FLAKES

            }//999

            @media (max-width:$lap){
                .the-day-awaits{
                    align-items: center; 
                    justify-content: center;                    
                    width:100%;

                    .kstl-rich-text{
                        width:100%;
                    }//KSTL RICH TEXT
                }//THE DAY AWAITS

                .flakes{
                    width:100%;
                }//FLAKES

            }//600


        }//FLEX FLAKES


/*----------------------------------------------------------------------GO-FORTH//
--------------------------------------------------------------------------------*/
        .go-fourth{


        }//GO FOURTH


/*-----------------------------------------------------------------------SHOW US//
--------------------------------------------------------------------------------*/
        .show-us{
            background:#3399ff;
            text-align:center;
            display: flex; 
            align-items: center; 
            justify-content: center; 
            cursor:pointer;
           //padding:40px 20px !important;

            a{
                color:$kWhite;
                text-decoration:none;
                display:flex;
                flex-direction:row;
                align-items: center;
                justify-content: center;

                img{
                    display:flex;
                    flex:0 1 auto;
                }//IMG

                span{
                    display:flex;
                    flex:0 1 auto;
                    padding-left:10px;
                    @extend %extraMedium;
                }//SPAN
            }//LINK

            &:hover{
                background:#857d6f;
            }//HOVER

            @media (max-width:$packBreak){
                padding:20px !important;
            }//999

            @media (max-width:$lap){
                min-height:100px;
            }//600
        }//SHOW US


/*--------------------------------------------------------------SWEET AND SIMPLE//
--------------------------------------------------------------------------------*/
        .sweet-simple{
            position:relative;
            border:5px solid $kBlue;
            background:$kWhite;
            padding:20px 10px 30px 10px !important;
            color:$kBlue;
            overflow:hidden;
            cursor:pointer;

            .show{
                transition: top 0.2s ease;
                position:absolute;
                top:100%;
                right:0px;
                bottom:0px;
                left:0px;
                background:$kBlue;
                color:$kWhite;
            }

            .hide{
                display:block;

                h2{
                    font-size:30px;
                    font-family: KelloggsSansBold;
                    line-height:1;
                }//H2

                p.body{
                    margin-top:10px !important;
                    @extend %extraSmall;
                    line-height:1.5;
                }//P
            }//HIDE

            .kstl-image-wrapper{
                    img{
                        display:block;
                        margin:0px auto !important;
                        padding-bottom:10px;
                    }//IMG
                }//KSTL IMAGE WRAPPER

            p.sources{
                @extend %tiny;
                line-height:1;
                font-family: KelloggsSansMedium;
            }//SOURCE


            &:hover{
                .show{
                    top:0px;

                    ol{
                        @extend %extraTiny;
                        margin-left:20px;
                        li{
                            padding-top:6px;
                        }//li
                    }//REFERENCES
                }//SHOW

                .hide{
                    display:none;
                }//HIDE
            }//HOVER

        }//SWEET SIMPLE

/*---------------------------------------------------------------BUILDING BLOCKS//
--------------------------------------------------------------------------------*/
        .building-blocks{
            position:relative;
            border:5px solid $kRed;
            background:$kRed;
            color:$kWhite;
            cursor:pointer;
            padding:20px 20px 10px 20px !important;
            min-height:215px;
            overflow:hidden;


            .show{
                transition: top 0.2s ease;
                position:absolute;
                top:100%;
                right:0px;
                bottom:0px;
                left:0px;
                background:$kWhite;
                color:$kRed;
                padding:20px !important;
                
                ol{
                    @extend %extraTiny;
                    margin-left:20px;
                    li{
                        padding-top:6px;
                        a{
                            color:$kRed;
                        }
                    }//li
                }//REFERENCES
            }//SHOW

            .hide{
                display:block;

                h2{
                    font-size:30px;
                    font-family: KelloggsSansBold;
                    line-height:1;
                }//H2

                p.body{
                    margin-top:10px !important;
                    @extend %extraSmall;
                    line-height:1.5;
                }//P

                img{
                    display:block;
                    margin:10px auto !important;
                }//IMG
            }//HIDE

            p.sources{
                @extend %tiny;
                line-height:1;
                font-family: KelloggsSansMedium;
            }//SOURCE


            &:hover{
                .show{
                    top:0px;
                }//SHOW

                .hide{
                    display:none;
                }//HIDE
            }//HOVER

            @media (max-width:$lap){
                min-height:250px;
            }//600
        }//BUILDING BLOCKS




        .inside-the-box{

        }//INSIDE THE BOX


/*----------------------------------------------------------------------GO FORTH*/

        .go-forth{

        }//GO FORTH


/*-------------------------------------------------------------------CORN FLAKES*/
        .corn-flakes{
            .banner{

            }

        }//CORN FLAKES

/*---------------------------------------------------------------------INSTAGRAM//
--------------------------------------------------------------------------------*/
        .instagram{
            position:static;
            background: url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/cereal-and-milk/instagram.jpg') no-repeat;
            background-size:100% 100%;
            overflow:hidden;

            .kstl-rich-text{
                position:relative;
                height:200px;

                @media (max-width:$packBreak){
                    height:calc((100vw /  5) *  2);
                }//998

                @media(max-width:$lap){
                    height:80vw;
                }//600

                .overlay{
                    transition: top 0.2s ease;
                    position:absolute;
                    top:100%;
                    right:0px;
                    bottom:0px;
                    left:0px;
                    z-index:1;
                    background:rgba(0, 0, 0, .5);
                    border:1px solid $kBlack;
                    text-align:center;
                    color:$kWhite;
                    display:flex;
                    flex-direction:row;
                    flex-wrap:wrap;

                    .inner{                      
                        p{
                            line-height:1;
                        }//P

                        p.strong{
                            padding-top:10px;
                            line-height:1.2;
                            font-weight:bold;
                        }//STRONG

                        .image{
                            padding-top:10px;
                            img{
                                
                            }//IMG
                        }//IMAGE
                    }//INNER
                }//OVERLAY
                
                &:hover{
                    .overlay{
                        top:0px;
                        cursor:pointer;

                        .inner{
                            flex:0 1 auto;
                            padding:20px;
                            margin:auto;
                        }//INNER
                    }//OVERLAY
                }//HOVER
            }//KSTL RICH TEXT
        }//INSTAGRAM

/*----------------------------------------------------------------------BALANCED//
--------------------------------------------------------------------------------*/
        .balanced{

        }//BALANCED



/*----------------------------------------------------------------BREAKFAST DATE//
--------------------------------------------------------------------------------*/
        .breakfast-date{
            background:$kBlue;
            display: flex; 
            align-items: center; 
            justify-content: center; 

            .grid__item{
                width: 200px;
                height:200px;

                .copy{
                    
                    color:$kWhite;
                    text-align:center;
                    

                    p{
                        padding:20px;
                        @extend %small;
                    }//P

                    p.image{
                        padding:0px;
                        img{
                        }
                    }//IMAGE WRAPPER
                }//COPY
            }//GRID ITEM
        }//BREAKFAST DATE


/*--------------------------------------------------------------------DELIGHTFUL*/
        .delightful{

        }

/*---------------------------------------------------------------------THINK BOX*/
        .think-box{
            background:$kBlue;
            align-items: center; 
            display: flex; 
            justify-content: center; 
        
            .grid__item{
                height:200px;
                width:224px;
                
                .kstl-image-wrapper{
                    img{
                        height:200px;
                        width:224px;
                        margin:0px auto;
                    }
                }//KSTL iMAGE WRAPPER
            }//GRID ITEM
        }//THINK BOX


/*----------------------------------------------------------------------BALANCED*/
        .balanced{
            min-height:300px;
            background:$kRed;
            color:$kWhite;
            position:relative;
            
            //padding:20px 20px 0px 20px !important;

            .kstl-rich-text{
                height:300px;
                display:flex;
                flex-direction:row;
                flex-wrap:wrap;
                padding:20px;

                .copy{
                    align-self:center;
                }//DIV

            }//KSTL RICH TEXT

            .image{
                width:100%;
                position:absolute;
                left:0px;
                bottom:0px;
                line-height:0px;

                img{
                    display:block;
                    width:80%;
                    margin:0px auto;
                }//IMG
            }//IMAGE

            @media(max-width:$lap){
                .kstl-rich-text{
                    //height:200px;
                    height:calc((100vw / 2) * 1);

                    .image{
                        img{
                            width:unset;
                        }//IMG
                    }//IMAGE
                }//KSTL RICH TEXT
            }//600


        }//BALANCED


/*-------------------------------------------------------------------SIMPLY GOOD*/
        .simply-good{

        }//BALANCED



/*-----------------------------------------------------------------AVERAGE PRICE//
--------------------------------------------------------------------------------*/
        .average-price{
            position:relative;
            border:5px solid $kBlue;
            background:$kWhite;
            padding:20px 10px 30px 10px !important;
            color:$kBlue;
            text-align:center;
            overflow:hidden;
            cursor:pointer;

            .show{
                transition: top 0.2s ease;
                position:absolute;
                background:$kBlue;
                color:$kWhite;
                top:100%;
                right:0px;
                bottom:0px;
                left:0px;
                padding:10px;
                text-align:left;
            }//SHOW

            .hide{
                display:block;
                p.body{
                    font-family: KelloggsSansMedium;
                    margin-top:10px !important;
                    @extend %medium;
                    line-height:1.5;
                }//P
                img{
                    display:block;
                    margin:10px auto !important;
                }//IMG
            }//HIDE

            p.sources{
                font-family: KelloggsSansMedium;
                @extend %tiny;
                line-height:1;
            }//SOURCE


            &:hover{
                .show{
                    top:0px;
                    ol{
                        @extend %extraTiny;
                        margin-left:20px;
                        li{
                            padding-top:6px;
                            a{
                                color:$kWhite;
                            }
                        }//li
                    }//REFERENCES
                }//SHOW

                .hide{
                    display:none;
                }//HIDE
            }//HOVER

/*-----------------------------------------------------------AVERAGE PRICE (999)*/
            @media (max-width:$packBreak){
                height: 788px;
                height:calc((100vw / 250) * 197);
                display: flex;
                align-items: center;
                justify-content: center;

                p.sources{
                    //font-size:16px;
                    font-size:calc((100vw / 125) * 2) !important;
                }//SOURCES

                .hide{
                    p.body{
                        //font-size:36px;
                        font-size:calc((100vw / 250) * 9);
                    }//BODY

                    p.extraSmall{
                        margin-top:10px !important;
                        //font-size:20px;
                        font-size:calc((100vw / 50) * 1);
                    }//EXTRA SMALL
                }//HIDE
            }//999

/*-----------------------------------------------------------AVERAGE PRICE (600)*/
            @media (max-width:$lap){
                height:413px;

                p.sources{
                    font-size:14px !important;
                }//SOURCES

                .hide{
                    margin-top:0px;
                    padding:40px;

                    p.body{
                        font-size:24px;
                    }//BODY

                    p.extraSmall{
                        font-size:calc((100vw / 30) * 1);
                    }//EXTRA SMALL
                }//HIDE
            }//600

/*-----------------------------------------------------------AVERAGE PRICE (350)*/
            @media (max-width:$tiny){
                height:413px;

                p.sources{
                    font-size:14px !important;
                }//SOURCES

                .hide{
                    p.body{
                        font-size:20px;
                    }//BODY

                    p.extraSmall{
                        font-size:12px;
                    }//EXTRA SMALL
                }//HIDE
            }//350


        }//AVERAGE PRICE

/*-----------------------------------------------------------------------SEE YOU//
--------------------------------------------------------------------------------*/
        .see-you{
            margin:5px !important;
            background:$kRed;
            color:$kWhite;
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;

            @media (max-width: 1000px){
                height:unset;
                padding:20px 10px !important;
            }//1000

            .grid__item{
                flex:0 1 auto;
                justify-content: center;
                align-self:center;
            }//GRID ITEM

            .kstl-image-wrapper{  
                img{
                    display:inline-block;
                    float:right;

                    @media (max-width: $packBreak){
                        display:block;
                        float:none;
                        margin:0px auto;
                    }
                }//IMG
            }//KSTL IMG WRAPPER

            p{
                a{
                    @extend %extraMedium;
                    color:$kWhite;
                    padding:10px !important;
                    text-decoration:none;
                    display:block;
                    margin:0px auto;
                    text-align:center;

                    &:hover{
                        text-decoration:underline;
                    }//HOVER
                }//A
            }//P
        }//SEE YOU
    }//CEREAL GRID

/*CEREAL AND MILK---------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/