footer {
    //padding-top:30px;

    .footer {
        margin-top: 0;

/*LOGO--------------------------------------------------------------------------*/
        .mob-logo-wrapper {
            max-width: 980px;
            margin: 0 auto !important;

            img {
                width: 100px;
                padding-top: 30px;
            }//IMG

            @media (max-width: $lap) {
                max-width: 320px;
                margin-right: auto;
                margin-left: 0px;
                padding-left: 0px;

                img {
                    width: 155px;
                }//IMG
            }//950
        }//MOB LOGO WRAPPER

/*CENTERED FOOTER---------------------------------------------------------------*/
        .centeredFooter {
            display: block;
            max-width: 1440px;
            margin: 0 auto!important;

/*-----------------------------------------------CENTERED FOOTER - (one quarter)*/
            .one-quarter {
                background: url(/content/dam/NorthAmerica/kelloggs/en_US/images/sitewide/greyDotVert.png) right top repeat-y;
                padding: 20px 4px 4px!important;
                min-height: 148px;

                @media (max-width:750px) {
                    min-height: 180px;
                }

                @media (max-width:601px) {
                    min-height: unset;
                    background: 0 0;
                }


            }//ONE QUARTER

/*----------------------------------CENTERED FOOTER - ONE QUARTER - (last child)*/
            .one-quarter:last-child {
                background: 0 0;
            }

        }//CENTERED FOOTER

/*-------------------------------------------------------------FOOTER(copyright)*/
        .copyright {
            background-color: $kBlack;
            color: white;
            font-size: 12px;
            max-width: 1440px;
            margin: 0 auto !important;

            .wrapper {
                max-width: 980px;
                margin: 0 auto;
                position: relative;

                p{
                    padding:20px;
                }

/*----------------------------------------------------------FOOTER (back to top)*/
                .back-top {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translateY(-50%);
                    width: 64px;
                    height: 64px;
                    background-color: white;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
                    border-radius: 9999px;
                    color: #2c2c2c;
                    text-decoration: none;

                    .label {
                        display: block;
                        font-size: 32px;
                        margin: 32px 0 0 4px;
                        transform: rotate(90deg);
                    }//LABEL

/*---------------------------------------------------------BACK TO TYPE (mobile)*/
                    @media (max-width: $lap) {
                        display:none;
                    }//601
                }//BACK TOP
            }//WRAPPER

            
            @media (max-width: $lap) {
                padding-top:0px;
                background-color: transparent;
                color: $kBlack;
                text-align:center;
            }//601
        }//COPYRIGHT



/*--------------------------------------------------------------FOOTER (widgets)*/
        .widgets {
            max-width: 980px;
            margin: 0 auto 40px auto !important;

            .widget-list {
                margin-right: 50px;

                ul {
                    list-style: none;

                    li{
                        a{
                            text-decoration: none;
                            display: block;
                            padding: 20px 0;
                            border-bottom: solid 1px #e6e6e6;
                            color: $kFooter;
                            background-color: transparent;
                            background-position: 5px 50%;
                            background-repeat: no-repeat;
                            padding-left: 40px;


                            &:hover{
                                text-decoration:underline;
                            }//HOVER
                        }//LINK

                        a.facebook {
                            background-image: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/social-icons__facebook.svg);
                            background-size:22px 22px;
                            @media (max-width:$innerBreak){
                                background-size: 30px 30px;
                            }
                        }
                        a.twitter {
                            background-image: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/social-icons__twitter.svg);
                            background-size:22px 22px;
                            @media (max-width:$innerBreak){
                                background-size: 30px 30px;
                            }
                        }
                        a.youtube {
                            background-image: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/social-icons__youTube.svg);
                            background-size:22px 22px;
                            @media (max-width:$innerBreak){
                                background-size: 30px 30px;
                            }
                        }
                        a.instagram {
                            background-image: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/social-icons__instagram.svg);
                            background-size:22px 22px;
                            @media (max-width:$innerBreak){
                                background-size: 30px 30px;
                            }
                        } 

                    }//LI

                    li:last-of-type a {
                        border-bottom: none;
                    }//LI LAST

                }//UL
            }//WIDGET LIST

/*----------------------------------------------------------FOOTER WIDGETS (999)*/
             @media (max-width: 999px){

                    margin-bottom: 0 !important;

                    .widget-list {
                        margin-right: 0px;
                        text-align:center;
                    }

                    .left-align{
                        .widget-list {
                            margin-right: 0px;
                            text-align:left;
                            

                            ul{
                                margin-left: 0px; 
                                li{
                                    a{
                                        margin:0px;
                                        padding:20px 0px;
                                    }
                                }
                            }//UL

                        }//WIDGET LIST
                    }//LEFT ALIGN

                    .widget-list.social-networks ul li {
                        display: inline-block;
                        width: 23%;

                        a {
                            text-indent: -999px;
                            overflow: hidden;
                            background-position-x: 50%;
                            border-bottom: none;
                        }//LINK
                    }//SOCIAL NETWORKS
             }//999

/*-------------------------------------------------------FOOTER WIDGETS (mobile)*/
             @media (max-width: 601px) {

                .widget-list {
                    margin-right: 0px;
                    text-align:center;

                    ul{
                        margin-left: 0px; 
                    }//UL

                }//WIDGET LIST

            }//601

        }//WIDGETS


/*---------------------------------------------------------------FOOTER (tablet)*/
        @media (max-width: 999px) {
            padding: 0 20px !important;

            .mob-footer-nav {
                margin: 15px 0 20px 0;

                    a {
                    display: block;       
                    border: solid 1px #cccccc;
                    border-radius: 999px;
                    padding: 10px 25px;
                    position: relative;
                    text-transform: uppercase;
                    font-size: 18px;
                    text-decoration: none;
                    color: #2c2c2c;
                    background-color: white;
                    
                    &::after {
                        display: block;
                        content: '+';
                        color: #cccccc;
                        position: absolute;
                        top: 50%;
                        right: 26px;
                        transform: translate(0, -50%);
                        font-size: 36px;
                    }//AFTER
                }//LINK
            }//MOB FOOTER NAV


        }//999
    }//FOOTER (CLASS)

/*FOOTER------------------------------------------------------------------------*/   
}                                                                               //
/*------------------------------------------------------------------------------*/