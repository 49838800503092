/*VIEW OUR FAQS link on CONTACT US page--------------------------------------------------------------------------*/
.view-faqs {
	margin: 0 0 20px !important;
	
	a {
		background: url(/content/dam/NorthAmerica/kelloggs/en_US/images/contact-us/arrow.png) 95.0% center no-repeat rgb(211,17,69);
		width: 100%;
		border-radius: 0 !important;
		text-transform: none !important;
		font-size: 24px;
	}
}

/*FAQ component on FAQ page--------------------------------------------------------------------------------------*/

.faq{
	.cta{
		position: relative;
		.cta_image{
			width: 100%;
			.kstl-image-wrapper{
				width: 100%;
				.kstl-responsive-image {
					width: 100%;
					img{
						width: 100%;
					}//IMG
				}//KSTL-RESPONSIVE-IMAGE
			}//KSTL_IMG WRAPPER
		}//CTA__IMAGE
	}//CTA

	.cta_text{
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		display: flex;
		color: #fff;
		text-align: center;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.kstl-rich-text{
			padding: 10px 20px;
			p{
				font-size: 18px;
			}

			p.view-answer{
				position: absolute;
				bottom: 20px;
				left: 0;
				right: 0;
				text-align: center;
				margin: 0px;
			}

			a{
				text-decoration: none;
				font-weight: bold;
				color: #fff;
				&:hover{
					text-decoration: underline;
				}
			}
		}//KSTL-RICH-TEXT
	}//CTA--TEXT


	.expandable__expanded{
		.cta{
			width: 50%;

			.cta_text{
				height: auto;
				.kstl-rich-text{
					padding: 50px 50px;
					p{
						font-size: 18px;
					}
				}//KSTL-RICH-TEXT
			}//CTA--TEXT
		}

		.third-width{
			width: 33.3%;
			padding: 10px 15px;
		}

		.half-width{
			width: 50%;
			padding: 10px 15px;
		}

		.full-width{
			width: 100%;
			padding: 10px 15px;
		}


		.answer-1{
			background: #610c20;
			color: #fff;
			padding: 20px 20px 40px 20px;
			text-align: center;
			p{
				font-size: 18px;
			}
		}
		
		.answer-2{
			background: #96471c;
			color: #fff;
			padding: 20px 20px 40px 20px;
			text-align: center;
			p{
				font-size: 18px;
			}
		}

		.answer-3{
			background: #a7013b;
			color: #fff;
			padding: 20px 20px 40px 20px;
			text-align: center;
			p{
				font-size: 18px;
			}
		}
	}

}//FAQ