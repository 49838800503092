.hero-banner{
    width:100%;

    .kstl-full-image-slide{
        background:transparent;

        .kstl-image-wrapper{
            width:100%;
            img{
                width:100%;
            }
        }//KSTL IMAGE WRAPPER


/*KSTL FEATURE INSET------------------------------------------------------------*/
        .kstl-feature-inset{
            width: 100%;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 0 !important;
            background: transparent;

            @media (max-width: $innerBreak){
                position: static;
            }

            .kstl-rich-text{
                                                
/*-------------------------------------------------------------------DESCRIPTION*/
                .description{
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.8+0,0.5+50,0+72 */
                    background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 72%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
                    background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 72%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: radial-gradient(ellipse at center, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 72%,rgba(255,255,255,0) 100%);
                    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=1 );
                    /* IE6-9 fallback on horizontal gradient */
                    padding: 40px;
                    width:370px;
                    margin-left: 10%;
                    margin-top: 20px;
                    //border: 1px solid red;

                    h2{
                        @extend %giant;
                    }//H2

                    p{
                        @extend %large;
                        line-height:1;
                    }//P

/*------------------------------------------------------------DESCRIPTION (1080)*/
                    @media (max-width:$desk){
                        margin-top:0%;
                        width: 60%;
                    }//1080

/*------------------------------------------------------------DESCRIPTION (950)*/
                    @media (max-width:$innerBreak){
                        margin-top:0%;
                        width: 80%;
                        padding:0px;
                    }//950


/*------------------------------------------------------------DESCRIPTION (600)*/
                    @media (max-width:$innerBreak){
                        width:100%;
                        margin:0px;
                        padding:20px 20px 40px 20px;
                    }//600

                }//DESCRIPTION

/*----------------------------------------------------------KSTL RICH TEXT (600)*/
                    @media (max-width:$innerBreak){
                        padding:0px;
                    }//600


            }//KSTL RICH TEXT
            

        }//KSTL FEATURE INSET

    }//FULL IMAGE SLIDE


/*HERO BANNER NO COPY-----------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .kstl-responsive-image{
            display:block;
            line-height:0px;
            width:100%;

            img{
                width:100%;
                padding:0px;
            }//IMG
        }//RESPONSIVE IMAGE



/*HERO BANNER-------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/
