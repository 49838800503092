@import "_vars";
@import "_mixins";
@import "_fonts";


html, body{
	//display: none !important;
	margin: 0;
	padding: 0;
	color:$kBlack;
	font-size: 1em;
	line-height: 1.5;
    min-width: 320px;
    font-family: KelloggsSansRegular !important;
    background: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/pattern-natural-white.jpg) 0 0 repeat;

/*BOX MODEL IS NOW UNIVERSAL----------------------------------------------------*/
*{
  &,
  &:before,
  &:after{
	@include vendor(box-sizing, border-box);
  }
}


div{
    @extend %clearfix;
}

.cta p,
.kstl-carousel-grid-slide p {
    font-size: 15px;
    font-size: 1.5rem;
    @media (max-width:601px){
      font-size: 13px;
      font-size: 1.3rem;
    }
}


.content_wrapper{
    //border:1px solid purple;
}



/*GRID--------------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
.grid {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-left: 0;
  padding: 0;
	.grid__item{
        margin-top: 0 !important;
        padding-top: 0 !important;
        margin-left: 0;
        padding: 0;
	}
}

/*------------------------------------------------------------------GRID (white)*/

.with-bg-white {
    position: relative;
    background-color: white;
    margin-top: 70px !important;
    margin-bottom: 70px !important;
    padding: 20px;

    @media(max-width:$lap){
        margin-top:40px !important;
    }
}

.grid .product-grid-container.with-bg-white.no-space {
    margin-top: 15px !important;
}

.with-bg-white::before {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 100%;
    left: 0;
    background: transparent url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/wave-top-white.png) 50% 0 no-repeat;
}

.with-bg-white::after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 100%;
    left: 0;
    background: transparent url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/wave-bottom-white.png) 50% 100% no-repeat;
}



/*MAIN CONTAINER----------------------------------------------------------------*/
#main_container{
	margin: 0 auto;
	overflow-x: hidden;
    text-align: left;
}


.container > .wrapper {
    padding: 40px 0;
}

.container > .wrapper {
    padding: 40px 0;
}

ol, ul {
    margin-left: 40px;
}

.nav ul {
    margin-left: 0px;
}



/*HR'S--------------------------------------------------------------------------*/
hr{
    display:block;
    height:1px;
    max-height:1px;
    line-height:0px;
    background:$kBlack;
    margin:20px 0px;
    border:0 !important;
}


/*MOBILE BREAK------------------------------------------------------------------*/
br.mob-br{
    display:none;
    @media (max-width:$lap){
        display:block;
    }//600
}//MOB BR


/*MOBILE HIDE-------------------------------------------------------------------*/
.mob-hide{
    @media (max-width:$innerBreak){
        display:none;
    }//600  
}//MOB HIDE

.lap-reveal{
    display:none;
    @media (max-width:$innerBreak){
        display:block;
    }//600  
}//MOB REVEAL

.mob-reveal{
    display:none;
    @media (max-width:$lap){
        display:block;
    }//600  
}//MOB REVEAL



/*MORE INCLUDED ITEMS-----------------------------------------------------------*/
@import "_error_wp";
@import "_typography";
@import "_header";
@import "_overlay";
@import "_search-results";
@import "_fullwidth";
@import "_heroCarousel";
@import "_heroBanner";
@import "_centeredContent";
@import "_homepage";
@import "_cerealAndMilk";
@import "_foodLanding";
@import "_recipeLanding";
@import "_recipeDetail";
@import "_brandDetail";
@import "_productDetail";
@import "_articleLanding";
@import "_corporatePage";
@import "_ourStoryDetail";
@import "_breakfastForBetterDays";
@import "_healthDetail";
@import "_tabPanel";
@import "_anchorArticles";
@import "_breadcrumbs";
@import "_buttons";
@import "_tables";
@import "_references";
@import "_relatedArticles";
@import "_mosaic";
@import "_cards";
@import "_stickyNav";
@import "_faqs";
@import "_cookies";

@import "_footer";


/*END HTML BODY-----------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/
