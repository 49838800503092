.table, .table-w-bg{
    width:100%;
    @extend %tiny;
    padding:0px;
    border-radius:10px;
    border:1px solid $kLightGrey;
    margin-bottom: 40px;

/*TABLE HEAD--------------------------------------------------------------------*/
    .tableHead{
        background:$kLightGrey;
        color:$kWhite;
        @extend %medium;
        padding:10px;
        border-radius:10px 10px 0px 0px;
        margin:-1px;

        @media (max-width:$innerBreak){
            text-align:center;
        }//950
    }//TABLE HEAD

/*SHOW HIDE---------------------------------------------------------------------*/
    .mob-hide{
        display:block;

        @media (max-width:$lap){
            display:none;
        }
    }

    .mob-reveal{
        display:none;

        @media (max-width:$lap){
            display:inline-block;
        }
    }


/*SUBHEAD-----------------------------------------------------------------------*/
    .subHead{
        font-weight:bold;
    }//SUBHEAD


/*TABLE ROW---------------------------------------------------------------------*/
    .table-row{
        width:100%;
        

        .two-thirds{
            float:left;
            display:inline-block;
            width:66.6%;
            padding:10px;

            //background:purple;
            @media (max-width:$lap){
                width:100%;

                span{
                    font-weight:bold;
                    
                }
            }//600
        }//TWO THIRDS

        .one-third{
            float:left;
            display:inline-block;
            width:33.3%;
            padding:10px;

            //background:yellow;
            @media (max-width:$lap){
                width:100%;


                span{
                    font-weight:bold;
                }
            }
        }//ONE THIRD

        .one-whole{
            width:100%;
            padding:10px;
            @extend %extraTiny;
            
        }//ONE WHOLE

        .footer{
            color:$kRed;
        }//FOOTER
    }//TABLE ROW

    .table-row:nth-of-type(odd){
        background:$transGrey;
    }

    .table-row:last-of-type{
        border-radius:0px 0px 10px 10px;
    }

    



/*END TABLE---------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/

.table-w-bg{
    background:$transGrey;

    .tableHead{
        margin-bottom:20px;
    }

    .cta_5{
        .cta_image{
            .kstl-image-wrapper{
                .kstl-responsive-image{
                    img{
                        border-radius:10px;
                        border:1px solid $kLightGrey;
                    }//IMG
                }//RESPONSIVE IMAGE
            }//KSTL IMAGE WRAPPER

        }//CTA IMAGE
        
        .cta_5_bottom{
            margin-top:0px;
            .cta_text{
                .kstl-rich-text{
                    p{
                        text-align:center;
                        @extend %small;
                        line-height:1;

                        b{
                            line-height:2;
                        }//B  
                    }//P
                }//KSTL RICH TEXT
            }//CTA TEXT
        }//CTA BOTTOM
    }//CTA


/*END TABLE WITH BACKGROUND-----------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/