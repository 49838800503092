.brandDetail{

/*SCROLLING HEADER--------------------------------------------------------------*/
    .internal-scroll{
        display:none;
        height:0px;
    }



/*BRAND VIDEO-------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .brand-video{


    }//BRAND VIDEO


/*OFFSET BRAND COPY-------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .offset-brand-copy{
        width:100%;
        padding:40px 0px;
        display:flex;


        @media (max-width: $lap){
            flex-direction:column;
        }//600


/*-------------------------------------------------OFFSET BRAND COPY (GRID ITEM)*/
        .grid__item{
            display:flex;
            flex: 0 1 auto;
            justify-content: center;
            align-items: center;
            padding:0px !important;
        }//GRID ITEM



/*-----------------------------------------------------OFFSET BRAND COPY (image)*/

        .kstl-image-wrapper{
            display:block;
            width:100%;

            .kstl-responsive-image{
                display:block;
                width:100%;

                img{
                    display:block;
                    width:100%;
                }//IMG
            }//KSTL RESPONSIVE IMAGE
        }//KSTL IMAGE WRAPPER

/*--------------------------------------------OFFSET BRAND COPY (KSTL RICH TEXT)*/
        .kstl-rich-text{
            display: block;
            width:100%;

            .brand-copy{
                padding:40px;

                h4{
                    width:100%;
                    @extend %large;
					color: #262626;
					margin-bottom: 10px;
                }//H4

                p{
                    width:100%;
					color: #666666;
					margin: 12px 0;
                }//P


            }//BRAND COPY
        }//KSTL RICH TEXT

    }//OFFSET BRAND COPY


    
/*REVERSE THE DIRECTION OF THE COLUMNS FOR SECOND BRAND COPY-------------------*/
    .grid__item:nth-of-type(3){
        .offset-brand-copy{
            @media (max-width: $lap){
                flex-direction:column-reverse;
            }//600
        }
    }
	
	
/*ROMANCE COPY--------------------------------------------------------------------------*/	
	.romance-copy {
		color: #666;
		text-align: center;

		@media (max-width:$lap){
			margin: 0 10px !important;
		}//600
		
		a {
			color: #666;
			text-decoration: none;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}


/*PRODUCT HEADING-----------------------------------------------------------------------*/
    .heading {
        border-bottom:solid 1px #dddddd;

        h3{
            text-align: center;
            font-family: KelloggsSansRegular;
            @extend %extraLarge;
            margin-bottom: 40px;

            @media (max-width:$lap){
                font-size:30px;
                line-height:100%;
            }//600

        }//H#
        
    }//HEADING


/*PRODUCT GRID------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
	.product_category h3 a {
		color: #2c2c2c;
		display: block;
		line-height: 1.2;
		margin: 0 40px 14px;
		text-align: center;
		text-decoration: none;
	}

	//hook into products grid on brand detail pages
	.products-container {
		.product_category {
			.product__image {
				@media (max-width:$packBreak){
					padding-bottom: 20rem;
				}
			}
			
			@media (max-width:$packBreak){
				.lap-one-third {
					width: 50%;
				}
			}
			
			@media (max-width:$lap){
				.palm-one-half {
					width: 100%;
				}
			}
		}
	}
	

    //.product_category {
        /*.product-result{
            //border:1px solid red;
			padding:20px !important;


            a.result-wrapper{
                display:block;
				text-align:center;
                color:$kBlack;
                text-decoration:none;

                img{
                    width:60%;
                }//IMG

                h3{
                    padding-top:20px;
                    padding-bottom: 2px;
                    font-family: KelloggsSansMedium;
                }//H3

                &:hover{
                    color:$kRed;
                    text-decoration:underline;
                }

            }//RESULT WRAPPER

        }//PRODUCT RESULT*/

   // }//PRODUCT GRID


/*RECIPE HEADING----------------------------------------------------------------*/
.recipe-heading {
	border-bottom:solid 1px #dddddd;
	
    h3 {
        text-align: center;
        font-family: KelloggsSansRegular;
        @extend %extraLarge;
		margin-bottom: 40px;

        @media (max-width:$lap){
            font-size:30px;
            line-height:100%;
        }//600

    }//H#
        
}


/*RECIPE SEARCH RESULTS---------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .kstl-search-list {
        background:transparent;
        padding:40px 0px 40px 0px !important;
        margin:0px -10px;
        display:flex !important;
        flex-direction:row;
        flex-wrap: wrap;
		
		@media (min-width:$packBreak) {
			.desk-one-third {
				width: 31.5%;
			}
		}

        @media (max-width:$lap){
            margin:0px;
			
			.palm-one-half {
				width: 100%;
			}
        }
		
		.lap-one-third {
			@media (min-width:$lap) and (max-width: 760px) {
				width: 47%;
			}
			@media (min-width: 760px) and (max-width:$packBreak) {
				width: 47.5%;
			}
		}
		
		.kstl-search-result-item {
			min-height: 300px;
			display: block;
			margin: 0 8px 16px 8px;
			background-color: #fff;
			text-decoration: none;
			font-size: 16px;
			line-height: 1.2;
			color: #666;
			overflow: hidden;
			transition: box-shadow 0.3s;
			box-sizing: border-box;				
			
			img {
				border-radius: 0;
			}			
		}


/*RECIPE CARD-------------------------------------------------------------------*/
        .recipe-result{
                display:flex;
                flex: 0 1 auto;
                padding:20px 10px;
                background:transparent;
                

                @media (max-width:$lap){
                    padding:0px;
                    margin:20px 0px;
                }

/*----------------------------------------------------------RECIPE RESULT (card)*/
            a.recipe-result__card{
                display:block;
                flex:1;
                background:$kWhite;
                font-family: KelloggsSansMedium;
                text-decoration:none;
                color:$kBlack;
                line-height:1.1;
                min-height: 300px;
                padding-bottom:40px;
                

                &:hover{
                    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
                }

                img{
                    width:100%;
                }//img

                h3{
                    padding:10px;
                    text-align:center;
                    text-decoration:none;
                }

                @media (max-width:$innerBreak){
                    min-height: unset;
                }

                @media (max-width:$lap){
                    padding-bottom:0px;
                    vertical-align: middle;
                    display:flex;
                    flex-direction: row-reverse;

                    img{
                        width:45%;
                        height:auto;
                        align-self: center;
                    }//img

                    h3{
                        width:55%;
                        padding:0px 10px;
                        text-align:center;
                        text-decoration:none;
                        font-size:90%;
                        align-self: center;
                    }
                }//600

                @media (max-width:$tiny){
                    padding:0px;
                }//350

            }//RECIPE CARD 
        }//RECIPE RESULT
        


        
    }//KSTL SEARCH LIST
	
	//Recipe pager
	.kstl-search-pager {
		margin-left: 0;
		
		li {
			margin: 0 5px;
			
			a {
				display: block;
				font-size: 16px;
				color: #2c2c2c;
				padding: 6px 13px;				
			}
			
			&.active {
				cursor: default;
				background-color: #2c2c2c;
				border-radius: 999px;
				
				a {
					color: #fff;
				}
			}
			
			&.first, &.prev, &.next, &.last {
				a {
					background: no-repeat center center;
					padding: 10px 25px;
					text-indent: -999em;
				}
			}
			
			&.first a, &.last a {
				background-image: url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/double-chevron.png');
			}
			
			&.prev a, &.next a {
				background-image: url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/single-chevron.png');
			}
			
			&.first a, &.prev a {
				transform: rotate(180deg);
			}
		}
	}

/*BRAND DETAIL------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/


/*.pagination {
	margin: 40px 0;
	width: 100%;
}

	
.pagination ul li a.previous-link img,
.pagination ul li a.first-link img {
	transform: rotate(180deg);
}

.pagination ul li a.is-active {
	cursor: default;
	background-color: #2c2c2c;
	color: #fff;
}*/


.heading-with-margin{
            display:block;
            flex:1;
            padding:40px 20px 20px 20px;
            text-align:center;

            h3{
                @extend %extraLarge;
                font-family: KelloggsSansRegular;
            }//H2

            @media (max-width:$lap){

                h3{
                    font-size:30px;
                }//H2

            }//600
        }


.brand-social{
    width:100%;
    padding: 20px;
    display: flex;
    justify-content: center;

    @media (max-width:$lap){
        flex-wrap: wrap;
    }

    .grid__item{
        width: auto !important;
        max-width: 33%;

        @media (max-width:$lap){
            flex: 1;
            flex-basis: 100% !important;
            width: 100% !important;
            max-width: 100%;
        }
    }

    .kstl-image-wrapper{
        padding: 10px;
        &:hover{
            opacity: .7;
        }
    }
}