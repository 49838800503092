.cards-container{

    .cards-container .grid__item:nth-of-type(even) .card {
        float: right;
    }
    
/*CARD--------------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .card{
        display: block;
        background-color: #8c8b8b;
        position: relative;
        float: left;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        transition: box-shadow 0.3s;
        /*width: 48%;*/
        
        margin: 20px 0;
        font-size: 0;
        /* technique to avoid undesirable spacings showing up as content. */

        &:hover{
            box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
        }

/*------------------------------------------------------------------CARD (image)*/
        .image {
            width: 100%;
        }//IMAGE

/*------------------------------------------------------------CARD (description)*/
        .description {
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translate(-50%, 0%);
            width: 80%;
            text-align: center;

/*---------------------------------------------------------CARD DESCRIPTION (h3)*/
            h3 {
                font-family: KelloggsSansMedium;
                color: $kBlack;
                @extend %large;
                padding-bottom: 8px;
                text-transform: uppercase;
            }

/*----------------------------------------------------------CARD DESCRIPTION (p)*/
            p {
                color: $kBlack;
                @extend %medium;
                font-family: KelloggsSansRegular;
            }

        }//DESCRiPTION

    }//CARD

/*----------------------------------------------CARD CONTAINER MEDIA QUERY (950)*/
    @media (max-width: $innerBreak) {

        .grid__item:nth-of-type(odd){
            .card {
                margin-right: 10px;
            }//CARD
        }//ODD

        .grid__item:nth-of-type(even){
            .card {
                margin-left: 10px;
            }//CARD
        }//EVEN

        .card{
            .description {
                top: 3vw;
                width: 90%;

                p{
                    display: none;
                }//P
            }//DESCRiPTION
        }//CARD
    }//950

/*----------------------------------------------CARD CONTAINER MEDIA QUERY (601)*/
    @media (max-width: $lap) {
        padding-left: 16px !important;
        padding-right: 16px !important;
        max-width: 514px !important;
        margin: 0 auto !important;

        .card {
            float: none;
            width: 100%;
            max-width: 482px;
            margin-left: 0 !important;

            .description {
                top: 10vw;
                width: 90%;

                p{
                    display: none;
                }//P
            }//DESCRIPTION
        }//CARD
    }//601


/*CARD TWO----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/

    .card2 {
        background-color: white;
        margin: 20px 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        text-decoration: none;
        display: block;
        transition: box-shadow 0.3s;
        min-height: 510px;

        &:hover {
            box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
        }

        .image {
            img {
                width: 100%;
            }
        }//IMAGE

/*----------------------------------------------------------CARD 2 (description)*/
        .description {
            padding: 40px 60px;
            text-align: center;
            min-height: 150px;

/*-------------------------------------------------------CARD 2 DESCRIPTION (h3)*/
            h3{
                font-family: KelloggsSansMedium;
                color: #2c2c2c;
                @extend %large;
                padding-bottom: 8px;
            }//H3

/*--------------------------------------------------------CARD 2 DESCRIPTION (p)*/        
            p{
                color: #666666;
                @extend %small;
            }//P
        }//DESCRIPTION
    }//CARD TWO


/*CARD THREE--------------------------------------------------------------------//
--------------------------------------------------------------------------------*/

    .card3 {
        background-color: white;
        margin: 20px 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        text-decoration: none;
        display: block;
        transition: box-shadow 0.3s;
        min-height: 567px;

        &:hover {
            box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
        }

        .image{
            img {
                width: 100%;
            }
        }//IMAGE

/*----------------------------------------------------------CARD 3 (description)*/
        .description {
            padding: 40px 60px;
            text-align: center;
            min-height: 150px;

/*-------------------------------------------------------CARD 3 DESCRIPTION (h3)*/
            h3{
                font-family: KelloggsSansMedium;
                color: #2c2c2c;
                @extend %large;
                padding-bottom: 8px;
            }//H3

/*--------------------------------------------------------CARD 3 DESCRIPTION (p)*/
            p{
                color: #666666;
                @extend %small;
            }//P

        }//DESCRIPTION

    }//CARD THREE
    

/*CARD FOUR---------------------------------------------------------------------//
                            static recipe tiles                                 //
--------------------------------------------------------------------------------*/
    .card4 {
        max-height: 320px;
        background-color: white;
        margin: 20px 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        text-decoration: none;
        display: block;
        transition: box-shadow 0.3s;

        &:hover {
            box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
        }

        .image{
            img{
                width: 100%;
            }
        }//IMAGE

/*----------------------------------------------------------CARD 4 (description)*/        
        .description {
            padding: 40px 60px;
            text-align: center;
            min-height: 150px;

/*-------------------------------------------------------CARD 4 DESCRIPTION (h3)*/
            h3{
                @extend %medium;
                font-family: KelloggsSansMedium;
                color: #2c2c2c;
                padding-bottom: 8px;
            }//H3

/*--------------------------------------------------------CARD 4 DESCRIPTION (p)*/
            p{
                color: #666666;
                @extend %small;
            }//P

        }//DESCRIPTION

    }//CARD FOUR


}//CARD CONTAINER