.vbox-overlay{
    //background: rgba(255,255,255,0.5);
   
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+50,0.8+75,0.5+100 */
background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.8) 75%, rgba(255,255,255,0.5) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.8) 75%,rgba(255,255,255,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.8) 75%,rgba(255,255,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#80ffffff',GradientType=0 ); /* IE6-9 */


   .vbox-container{
        background:transparent;
        padding: 15px 25px 120px 25px !important;
        max-width:unset !important;
        width:100%;
        margin:0px;

        .vbox-content{
            background:transparent;
            //max-width:1400px;
            width:100%;
            margin:0px auto !important;
            padding:0px 0px 40px 0px;

            .vbox-inline{
                background:transparent !important;
            }
     
/*SEARCH OVERLAY----------------------------------------------------------------*/
            .overlay-search-container{
                background: linear-gradient(180deg, white, white 95%, transparent);

                .search-box{
                    margin-top:80px !important;

                    .search-textbox{
                        text-align:center;

                        .search-textbox__input{
                            border-radius:0px;
                            border-top:0px;
                            border-right:0px;
                            border-bottom:1px solid $kLightGrey;
                            border-left:0px;
                            width:60%;
                            @extend %extraLarge;

                        }//INPUT
                    }//SEARCH TEXTBOX

                    @media (max-width: $lap) {
                        margin-top:40px !important;
                    }//601
                    
                }//SEARCH BOX
            }//SEARCH OVERLAY   

/*ASK US OVERLAY----------------------------------------------------------------*/
            .overlay-ask-us-container{
                max-width:800px;
                width: 80%;
                background:#fff;
                -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                margin: 20px auto !important;
                padding: 60px 40px !important;                


            }//ASK US OVERLAY

/*------------------------------------------------------------VBOX CONTENT (601)*/
            @media (max-width: $lap) {
                padding:0px;
            }//601

        }//VBOX CONTENT
   }//VBOX CONTAINER


/*CLOSE BUTTON------------------------------------------------------------------*/
    .vbox-close{
        right:40px !important;
        font-family: KelloggsSansMedium;
        background:transparent;
        color:$kRed !important;
        text-indent:0px;
        @extend %extraLarge;
        padding: 0px 0px 0px 8px;
        line-height: 42px;

        @media (max-width: $lap) {
            top:20px !important;
            right:20px !important;
        }//601

    }//VBOX CLOSE

}//VBOX OVERLAY