.recipe-landing{
    //border:1px solid purple;

/*CAROUSEL----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .kstl-feature-inset{
		bottom: 22%;
        left: 0;

/*SLIDE ONE---------------------------------------------------------------------*/
        .slideOne{
            margin:40px 0px 0px calc((100vw / 96) * 5);
            width:550px;
            color:$kWhite;

            h1{
                font-family:KelloggsSansMedium;
                font-size:calc((100vw / 300) * 11);
                line-height:1;
            }//h1

            p{
                margin-top:10px;
                line-height:1.1;
            }//P

/*---------------------------------------------------SLIDE ONE MEDIA QUERY (950)*/
        @media (max-width:$innerBreak){
            margin:0px 0px 0px calc((100vw / 95) * 2);
        }//950
            
/*---------------------------------------------------SLIDE ONE MEDIA QUERY (601)*/
            @media (max-width:$lap){
                width:90%;
                margin:0px auto 10px auto;
                color:$kBlack;
                h1{
                    font-size:calc((100vw / 12)* 1);
                }//H1
            }//601

        }//SLIDE ONE
    }//KSTL FEATURE INSET


/*SEARCH RESULTS----------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .kstl-search-list{
        background:transparent;
        padding:40px 0px 40px 0px !important;
        margin:0px -10px;
        display:flex !important;
        flex-direction:row;
        flex-wrap: wrap;

        @media (max-width:$lap){
            margin:0px;
        }


/*RECIPE CARD-------------------------------------------------------------------*/
        .recipe-result{
                display:flex;
                flex: 0 1 auto;
                padding:20px 10px;
                background:transparent;
                

                @media (max-width:$lap){
                    padding:0px;
                    margin:20px 0px;
                }

/*----------------------------------------------------------RECIPE RESULT (card)*/
            a.recipe-result__card{
                display:block;
                flex:1;
                background:$kWhite;
                font-family: KelloggsSansMedium;
                text-decoration:none;
                color:$kBlack;
                line-height:1.1;
                min-height: 300px;
                padding-bottom:40px;
                

                &:hover{
                    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
                }

                img{
                    width:100%;
                }//img

                h3{
                    padding:10px;
                    text-align:center;
                    text-decoration:none;
                }

                @media (max-width:$innerBreak){
                    min-height: unset;
                }

                @media (max-width:$lap){
                    padding-bottom:0px;
                    vertical-align: middle;
                    display:flex;
                    flex-direction: row-reverse;

                    img{
                        //float:right;
                        //display:inline-block;
                        width:45%;
                        height:auto;
                        align-self: center;
                    }//img

                    h3{
                        //float:right;
                        //display:inline-block;
                        width:55%;
                        padding:0px 10px;
                        text-align:center;
                        text-decoration:none;
                        font-size:90%;
                        align-self: center;
                    }
                }//600

                @media (max-width:$tiny){
                    padding:0px;
                }//350

            }//RECIPE CARD
        }//RECIPE RESULT
        


        
    }//KSTL SEARCH LIST




/*RECIPE LANDING----------------------------------------------------------------*/    
}                                                                               //
/*------------------------------------------------------------------------------*/