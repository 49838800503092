.tab-controls{

/*------------------------------------------------TAB CONTROLS MEDIA QUERY (600)*/
    @media(max-width:$lap){
        width:100%;
        //margin:25px -20px;
    }//600

/*TAB BUTTONS-------------------------------------------------------------------*/
    .tab_buttons{
        width:100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin:0px auto;
        

        .tab_button{
            text-align: center;

            .tab_link{
                font-size: 18px;
                background: transparent;
                border: 2px solid transparent;
                padding: 4px 8px;
                margin: 0px 4px !important;

                &:hover{
                    background:transparent;
                    color: $kRed;
                    border-bottom: 2px solid $kRed;
                }//HOVER
            }//TAB LINK
            
        }//TAB BUTTON

        .activeLink{
             .tab_link{
                color: $kRed !important;
                border-bottom: 2px solid $kRed;
             }
        }//ACTIVE LINK

/*-------------------------------------------------TAB BUTTONS MEDIA QUERY (600)*/
        @media(max-width:$lap){
            display: block;
            width: 100vw;
            position: relative;
            text-align: center;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #dad9d9);
            padding: 20px;

            .tab_button{

                .tab_link{
                    
                }//TAB LINK
                
            }//TAB BUTTON

        }//600

/*-------------------------------------------------TAB BUTTONS MEDIA QUERY (350)*/
        @media(max-width:$tiny){
            
            .tab_button{
                margin:0px 8px;

                .tab_link{
                    font-size:90%;
                }//TAB LINK
                
            }//TAB BUTTON

        }//350


    }//TAB BUTTONS

/*TAB CONTROLS------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/


/*FILTERS-----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
.kstl-search-filter-container{
    padding: 20px;
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .kstl-search-filtervalue{
        @extend  %kstl-default-button;
        padding: 12px 30px 12px 30px !important;
        text-align: center;
        font-size: 14px;
    }

}






/*TAB WRAPPER-------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
.tab_wrapper{

    .tab_content{
        max-height:unset !important;
        @media(max-width:$lap){
            .mobile_tab_button{
                display:none;
            }//MOBILE TAB BUTTON
        }//600

    }//TAB CONTENT

    .activeTab{
        @media(max-width:$lap){
            max-height:unset !important;

            .tab_inner{
                max-height:unset !important;
                padding-bottom:24px;
            }//TAB INNER

        }//600
    }//ACTIVE TAB


/*KSTL CAROUSEL GRID SLIDE------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .kstl-carousel-grid-slide{
        overflow: visible;
        background: transparent;

        
/*KSTL SEARCH COUNT-------------------------------------------------------------*/
        .kstl-search-count{
            @extend %extraMedium;
            font-family: KelloggsSansRegular;
            span{
                font-family: KelloggsSansBold !important;
                font-weight: 900;
            }
        }//KSTL SEARCH COUNT

/*KSTL SEARCH LIST--------------------------------------------------------------*/
        .kstl-search-list{
            display:block;
            overflow:visible;
            padding:40px 0px 20px 0px;

/*--------------------------------------------KSTL SEARCH LIST (results wrapper)*/
            .result-wrapper{
                margin:0px;
                

                @media(max-width:$lap){
                    border-bottom:1px solid $kLightGrey;
                    padding-bottom:20px;
                }//600

                img{

                    @media(max-width:$lap){
                        width:80%;
                        margin:0px 15% 0px 5%;
                     }//600

                    @media(max-width:$tiny){
                        width:unset;
                        margin:unset;
                    }//600

                }//IMAGE

                .h3{

                }//H3
            }//RESULTS WRAPPER


/*-----------------------------------KSTL SEARCH LIST (results wrapper centered)*/
            .result-wrapper__centered{
                display:block;
                
                @media(max-width:$lap){
                    width:100%;
                    margin:0px;

                    img{
                        display:block;
                        width:80%;
                        margin:10px auto;
                    }//IMG
                }//600
            }//RESULTS WRAPPER CENTERED
            
        }//KSTL SEARCH LIST

/*KSTL SEARCH FILTER VALUE------------------------------------------------------*/
        .kstl-search-filtervalue{
            @media(max-width:$lap){
                width: 45%;
                padding: 12px 0;
            }//600
        }//FILTER VALUE

/*KSTL SEARCH PAGER-------------------------------------------------------------*/
        .kstl-search-pager {
        margin-left: 0;
        margin-bottom: 40px;
		
		li {
			margin: 0 5px;
			
			a {
				display: block;
				font-size: 16px;
				color: #2c2c2c;
				padding: 6px 13px;				
			}
			
			&.active {
				cursor: default;
				background-color: #2c2c2c;
				border-radius: 999px;
				
				a {
					color: #fff;
				}
			}
			
			&.first, &.prev, &.next, &.last {
				a {
					background: no-repeat center center;
					padding: 10px 25px;
					text-indent: -999em;
				}
			}
			
			&.first a, &.last a {
				background-image: url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/double-chevron.png');
			}
			
			&.prev a, &.next a {
				background-image: url('/content/dam/NorthAmerica/kelloggsca/en_CA/images/site-wide/single-chevron.png');
			}
			
			&.first a, &.prev a {
				transform: rotate(180deg);
			}
		}
	}

    }//KSTL GRID SLIDE

/*TAB WRAPPER-------------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/