/*BREAKFAST FOR BETTER DAYS-----------------------------------------------------//
--------------------------------------------------------------------------------*/
.breakfast4BetterDays{
  display:block;
  font-size:21px;//fallback for safari and IE
  margin-top:40px;



/*BASIC FONT SETTINGS-----------------------------------------------------------*/

  .yellow{
    font-family: KelloggsSansBold;
    color:#f4ce7c;
  }

  .big{
    font-size:300%;
  }

  .mid{
    font-size:160%;
  }

  h1{
    color:$kWhite;
  }

  h2, h3, h4{
    font-family: KelloggsSansBold;
    color:#f4ce7c;
    font-size:142%;

    @media (max-width: $lap){
      font-size:200%;
    }

    @media (max-width: $palm){
      font-size:150%;
    }
  }//END H TAGS

  p, .cta p{
    font-size:100%;

    a{
      //font-size:70% !important;
    }

    @media (max-width: $palm){
      font-size:70%;

    }
  }//END P TAGS

  sup{
    vertical-align:6px !important;
    font-size:45% !important;
  }


/*HERO--------------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
@media (max-width:$lap){
  .hero-banner{
    position: relative;
    background:#ce0f45;

    &:after{
        position:absolute;
        bottom:-50px;
        content: "";
        display: block;
        width: 100%;
        height: 50px;
        background: transparent url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/after.png) no-repeat !important;
        background-size:100% 100%;
    }
  }
}

  .hero{
    h1{
      margin:0px 30px 0px 30px;

      @media (max-width:$packBreak){
        color:$kBlack;
      }

      @media (max-width:$lap){
        color:$kWhite;
      }
    }
  }



/*-----------------------------------------------------------------THE LANDSCAPE//
--------------------------------------------------------------------------------*/
.blue-bg{
  padding:20px !important;
  background:#3721bf;
  font-family: KelloggsSansMedium;

  .theLandscape{
    color:#fff;
    position:relative;
    padding:20px !important;
    background:#3721bf url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) 200px 40px repeat-x;
    min-height:243px;

    @media (max-width:$packBreak){
      background:transparent;
    }//999

    h2{
      background:#3721bf;
      width:230px;
      margin-left:-20px;
      padding-left:20px;

      @media (max-width:$packBreak){
        width:unset;
        padding-bottom:20px;
        background:#3721bf url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) left bottom repeat-x;
          width:unset;
      }//999
    }//END H2

/*----------------------------------------------------------THE LANDSCAPE (LEFT)*/
    .left{
      padding-top:10px;
      text-align:center;

      .heightFix{
        //line-height:80%;
        display:block;
        margin:-14px 0px;
        padding-bottom:10px;
      }//END HEIGHT FIX

    }//LEFT

/*----------------------------------------------------THE LANDSCAPE (SILHOUETTE)*/
    .silhouette{
      width:100%;
      .kstl-image-wrapper{
        width:100%;
        img{
          display:block;
          width:82%;
          margin:0px auto !important;

          @media (max-width:$packBreak){
            width:24%;
            margin:20px auto !important;
          }//600
        }//IMG

        @media (max-width:$packBreak){
          padding-bottom:0px;
        }//600
      }//KSTL IMAGE WRAPPER
    }//SILHOUETTE

/*--------------------------------------------------------THE LANDSCAPE (MIDDLE)*/
    .middle{
      line-height:75%;
      padding:20px 0px 0px 0px !important;
      text-align:center;

      .yellow{
        line-height:110%;
      }
    }//MIDDLE

/*---------------------------------------------------------THE LANDSCAPE (ARROW)*/
    .arrow{
      width:100%;

      .kstl-image-wrapper{
        width:100%;
        img{
          display:block;
          width:82%;
          margin:0px auto;

          @media (max-width:$packBreak){
            transform: rotate(90deg);
            width:24%;
            margin:0px auto !important;
          }//600
        }//IMG

        @media (max-width:$packBreak){
          padding-bottom:0px;
        }//600
      }//KSTL IMAGE WRAPPER
    }//ARROW

/*---------------------------------------------------------THE LANDSCAPE (RIGHT)*/
    .right{
      text-align:center;
      padding:10px 0px 0px 10px !important;

      .big{
        line-height:120%;
        font-size:190%;
        text-transform:uppercase;
      }

      sup{
        font-size:30% !important;
        vertical-align: 21px !important;
      }

    }//RIGHT
  }//LANDSCAPE
}//BLUE BG



/*EFFECTS ON CHILDREN-----------------------------------------------------------//
--------------------------------------------------------------------------------*/

.orange-bg{
  padding:20px !important;
  background:#e46d30;
  font-family: KelloggsSansMedium;
  color:$kWhite;

  .effectsOnChildren{
    position:relative;
    background:#e46d30 url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) 0px 28px repeat-x;
    min-height:420px;

    @media (max-width:$packBreak){
        background:transparent;
      }//999

    h3{
      text-align:left;
      background:#e46d30;
      width:340px;
      margin-left:-20px;
      padding-left:20px;
      
      @media (max-width:$packBreak){
        margin-left:0px;
        padding-bottom:20px;
        background:#e46d30 url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) left bottom repeat-x;
        width:unset;
      }//999

    }//END H3


/*--------------------------------------------------EFFECTS ON CHILDREN (KIDDOS)*/
    .kiddos{
      text-align:center;

      img{
        margin-top:20px;
        
        @media (max-width:$packBreak){
          width:60%;
          margin:60px auto 20px auto !important;
        }//999
      }//IMG
      p{
        font-size:100%;
        padding:20px 0px;
      }
    }//END KIDDOS

/*---------------------------------------------------EFFECTS ON CHILDREN (GRAPH)*/
    .graph{
      background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dotVert.png) left top repeat-y;
      padding:20px !important;

      @media (max-width:$packBreak){
        background:transparent;
      }//999

      .mob-center{
          @media (max-width:$packBreak){
            text-align:center;
          }//999
        }//MOB CENTER

      .flex-graph{
        margin:20px 0px;
        width:100%;
        font-size:78%;
        align:left;
        border-collapse:separate;

        @media (max-width:$packBreak){
          width:540px;
          margin:20px auto;
        }//999

        @media(max-width:660px){
          width:100%;
        }//660

        .row{
          display:flex;
          
          @media(max-width:660px){
            flex-direction:column;
          }//660

          

          .graphTable{
            font-family: KelloggsSansBold;
            width:162px;
            height:34px;
            border:1px solid #fff;

            @media(max-width:660px){
              width:100%;
            }//660
            

            .barGraph{
              padding:0px 0px 0px 4px;
              height:32px;
              background:#ffd776;
              font-size:143%;
              &:after{
                content: none;
              }//AFTER
            }//END BAR GRAPH

          }//END TD

          .descripter{
            padding-left:10px;
            align-self:center;
            @media(max-width:660px){
              width:100%;
              padding-left:0px;
              padding-top:10px;
            }//660
          }

        }//END ROW

        .row + .row{
          margin-top:10px;
        }


      }//TABLE
    }//GRAPH

/*----------------------------------------------EFFECTS ON CHILDREN (FULL WIDTH)*/
    .fullWidth{
      background:#e46d30 url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) left top repeat-x;
      margin:10px 0px 0px .5%;
      padding-top:20px !important;

/*------------------------------------------------EFFECTS ON CHILDREN (TEACHERS)*/
      .teachers{
        //border:1px solid red;

        .kstl-image-wrapper{

          img{

          }//IMG

          @media(max-width:660px){
            width:100%;

            img{
              display: block;
              width: 30%;
              margin: 20px auto;
            }//IMG
          }//660
        }//KSTL IMG WRAPPER

        .kstl-rich-text{
            p{
              margin-top:0px;
            }//END P

             @media(max-width:660px){
                width:100%;
                text-align:center;
             }//660
          }//RICH TEXT

        

      }//END TEACHERS

/*-------------------------------------------------EFFECTS ON CHILDREN (LEARNING)*/
      .learning{
        padding-top:16px;

        .oneThirtyTwo{
          padding-top:6px !important;
          font-family: KelloggsSansBold;
          color:#f4ce7c;
          font-size:300%;
          line-height:90%;

          @media(max-width:$lap){
            width:100%;
            font-size:350%;
            text-align:center;
            margin:20px auto;
          }//600

        }//END 132


        .three-quarters{
        
          .kstl-rich-text{
            padding-left:40px;

            @media(max-width:660px){
              padding:0px;
              text-align:center;
            }
          }
        }
      }//END TEACHERS

    }//END FULL WIDTH

  }//EFFECTS ON CHILDREN

}//ORANGE BG


/*HELPING HAND------------------------------------------------------------------//
--------------------------------------------------------------------------------*/

  .red-bg{
    background-color:#cf0d46;
    padding:20px !important;
    color:$kWhite;

    .helpingHand{
      position:relative;
      background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) 0px 28px repeat-x;
      height:auto;
      padding:10px 20px;

      @media (max-width:$packBreak){
        background:transparent;
      }//999

    
      h4{
        text-align:left;
        background:#cf0d46;
        width:270px;
        margin-left:-20px;
        padding-left:20px;

        @media (max-width:$packBreak){
          padding-bottom:20px;
          background:#cf0d46 url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) left bottom repeat-x;
          width:unset;
        }//999
      }//END H4

      p{
        //border:1px solid #fff;
        text-align:center;
        padding:20px 0px;
        font-size: 98%;
      }//P

      .kstl-image-wrapper{
        padding:0px !important;


      .kstl-responsive-image{
        
        img{

        }//IMG

        
      }//KSTL RESPONSIVE IMG
    }//KSTL IMAGE WRAPPER

    }//END HELPING HAND


/*---------------------------------------------------------------------DOTTED HR*/
    .dotted-hr{
      display:block;
      height:6px;
      margin:20px auto !important;
      background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) 0px 0px repeat-x;
    }


/*BOWL--------------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .bowl{
      font-family: KelloggsSansMedium;
      clear:none;
      text-align:center;
      padding-top:20px;
      padding-bottom:20px;

      p{
        padding:0px !important;
      }

      p.yellow{
        font-size: 300%;
        line-height:86%;
        @media (max-width:500px){
          font-size:200%;
        }//500
      }

      .left {

      }

      img{
        width:100%;
        margin:10px 0px 0px -40px;
        @media (max-width:$packBreak){
          width:50%;
          margin:20px auto !important;
        }//999

        @media (max-width:500px){
          width:80%;
          margin:20px auto !important;
        }//500

      }//END IMG

    }//END BOWL




/*TEACHERS----------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .teachers{
    	background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) left top repeat-x;
    	margin-top:20px;
      padding:30px 0 !important;
      text-align:left;

      @media (max-width:$packBreak){
        width:unset;
      }


      .left{
        font-size: 300%;
        width:156px;
        text-align:right;

        @media (max-width:$packBreak){
          width:unset;
          text-align:center;
        }
      }//END LEFT

      .right{
        font-size:90%;
        padding-left:10px;
        @media (max-width:$packBreak){
          text-align:center;
        }
      }//END P

    }//END TEACHERS


/*CLUBS-------------------------------------------------------------------------//
--------------------------------------------------------------------------------*/
    .clubs{
      position: relative;
    	background: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dotVert.png) left top repeat-y;
    	width: 340px;
    	height: 300px;
    	text-align: right;
      padding-left: 60px;
      
      @media (max-width:$packBreak){
        width:unset;
        height:auto;
        background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/dot.png) left top repeat-x;
        padding-left:0px;
      }

      .kidHeart{
        width:270px;
      	height:96px;
      	background: url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/kidHeart.png) right center no-repeat;
      	padding: 6px 44px 0 0;
        margin-top:-20px;


        .big{
          line-height:80%;
        }//END BIG

        @media (max-width:$packBreak){
          margin:20px auto 0px auto;
          height:unset;
        }//999
      }//END KID HEART

      .kidHeart + p{
        width:266px;
        margin:0px 0px 0px 0px;
        padding:0px;

        @media (max-width:$packBreak){
          width:unset;
          text-align:center;
        }
      }//END P

      .mob-show{
        display:none;

        @media (max-width:$packBreak){
          display:block;
        }
      }

      .map{
        width:296px;
        height:284px;
        margin:-64px 0px 0px -115px;
        background:url(/content/dam/NorthAmerica/kelloggsca/en_CA/images/breakfast-for-better-days/map.png) no-repeat;
        z-index:90;
  
        @media (max-width:$packBreak){
          margin:40px auto 20px auto;
        }

      }//END MAP


    }//END CLUBS
  
  
  }//RED BG




/*------------------------------------------------------------------------MOBILE*/
  @media (max-width: 601px){
    font-size:22px;
    font-size:calc((100vw / 300) * 11);
  }//END 601



/*--------------------------------------------------------------------------TINY*/
  @media (max-width: 401px){
    font-size:calc((100vw / 50) * 3);
  }//END 401


/*END BREAKFAST FOR BETTER DAYS-------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/

#page-content{
  padding-bottom:40px;
}



.reference-section{
  h2{
    color:$kBlack;
  }
}//REFERENCE SECTION