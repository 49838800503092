/*CENTERED CONTENT--------------------------------------------------------------//
        This is the 980 width column that makes up the majority of the site.    //
--------------------------------------------------------------------------------*/

.centeredContent {
    display: block;
    max-width: 1020px;
    padding:20px !important;
    //border:1px solid red;
    margin: 0 auto!important;
	
	@media (max-width:$lap){
		padding:20px 0 !important;
	}	

/*-------------------------------------------------------CENTERED CONTENT (half)*/
    .centeredContent--half{
        margin:0px -20px;
        padding:0px;

        .grid__item{
            padding:0px !important;
            //border:1px solid red;
            .cta{
                padding:0px 10px 0px 20px;

                @media (max-width:$lap){
                    padding:0px 20px 0px 20px;
                }
            }
        }

        .grid__item + .grid__item{
            margin-left:0px;
            //padding:0px 0px 0px 10px;
            .cta{
                padding:0px 20px 0px 10px;

                @media (max-width:$lap){
                    padding:0px 20px 0px 20px;
                }
            }
        }

/*---------------------------------------------------CENTERED CONTENT HALF (cta)*/
        .cta_1{
            @extend %clearfix;
            position:relative;
            text-align:center;
            padding-top:0px;
            //border:1px solid blue;

            .cta_image{
                width:100%;
                padding-bottom:20px;

                a{
                    &:hover{
                        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
                    }

                    .kstl-responsive-image {
                        @media (max-width:$lap){
                            img{
                                width:100%;
                                padding:0px;
                            }
                        }//601
                    }//KSTL-RESPONSIVE-IMAGE

                }//LINK
            }//CTA IMAGE

            .cta_text{
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translate(-50%, 0%);
                width: 80%;
                text-align: center;

                .description{

                    h3{
                        font-family: KelloggsSansMedium;
                        @extend %large;
                    }//H3

                    p{
                        font-family: KelloggsSansRegular !important;
                        @extend %medium;
                        line-height:1.1;

                        @media (max-width:$innerBreak){
                            display:none;
                        }
                    }//p

                }//DESCRIPTION
            }//CTA TEXT
        }//CTA
    }//HALF


/*------------------------------------------------------CENTERED CONTENT (whole)*/
    .centeredContent--whole{
        width:100%;
        padding-top:24px !important;

        .grid__item{
            padding-top:0px !important;
        }

        .kstl-full-image-slide {
            position:relative;
            display:block;
            background:transparent !important;
            padding-bottom:60px;

            .kstl-feature-inset{
                position: absolute;
                top:0px;
                right:0px;
                bottom:0px;
                left:0px;
                display:block;
                width:100%;
                background:transparent !important;

/*------------------------------------CENTERED CONTENT-WHOLE (description--left)*/
                .description--left{
                    position: absolute;
                    top: 40px;
                    left: 60px;
                    background-color: white;
                    width: 320px;
                    height: 380px;
                    padding: 30px 20px;
                    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
                    text-align: center;

                    .icon{
                        display: inline-block;
                        margin: 15px 0;
                    }//ICON

                    p.latest{
                        font-family: KelloggsSansRegular;
                        @extend %extraMedium;
                        color: #b51946;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }

                    h3{
                        font-family: KelloggsSansMedium;
                        color:$kBlack;
                        @extend %giant;
                        line-height:0.8;
                        padding-bottom:15px;
                    }//H3

                    h3.promo{
                        @extend %large;
                    }//H3

                    h6{
                        color:$kBlack;
                        @extend %large;
                        line-height:1.1;
                    }//H6

                    p.promo{
                        @extend %extraSmall;
                        line-height: 1.5;
                    }

/*-------------------------------------------------------DESCRIPTION--LEFT (950)*/
                    @media (max-width:$innerBreak){
                        position:static;
                        width: 80%;
                        height: auto;
                        margin:0px auto;
                    }//950
                    
/*-------------------------------------------------------DESCRIPTION--LEFT (400)*/
                    @media (max-width:$palm){
                        width: 90%;

                        h3{
                            font-size: calc((100vw / 12)* 1);
                        }//H3

                        h6{
                            font-size: calc((100vw / 20)* 1);
                        }//h6
                        
                    }//400

/*-------------------------------------------------------DESCRIPTION--LEFT (350)*/
                    @media (max-width:$tiny){
                        width: 95%;
                    }//350
                    
                }//DESCRIPTION


/*-----------------------------------CENTERED CONTENT-WHOLE (description--right)*/
                .description--right{
                    position: absolute;
                    top: 40px;
                    right: 60px;
                    background-color: white;
                    width: 320px;
                    height: 380px;
                    padding: 30px 20px;
                    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
                    text-align: center;

                    .icon{
                        display: inline-block;
                        margin: 15px 0;
                    }//ICON

                    p.latest{
                        font-family: KelloggsSansRegular;
                        @extend %extraMedium;
                        color: #b51946;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }//LATEST

                    h3{
                        font-family: KelloggsSansMedium;
                        color:$kBlack;
                        @extend %giant;
                        line-height:0.8;
                        padding-bottom:15px;
                    }//H3

                    h3.promo{
                        @extend %large;
                    }//H3


                    h6{
                        color:$kBlack;
                        @extend %large;
                        line-height:1.1;
                    }//H6

                    p.promo{
                        @extend %extraSmall;
                        line-height: 1.5;
                    }

/*------------------------------------------------------DESCRIPTION--RIGHT (950)*/
                    @media (max-width:$innerBreak){
                        position:static;
                        width: 80%;
                        height: auto;
                        margin:0px auto;
                    }//950                 

/*------------------------------------------------------DESCRIPTION--RIGHT (400)*/
                    @media (max-width:$palm){
                        width: 90%;

                        h3{
                            font-size: calc((100vw / 12)* 1);
                        }//H3

                        h6{
                            font-size: calc((100vw / 20)* 1);
                        }//h6

                    }//400

/*------------------------------------------------------DESCRIPTION--RIGHT (350)*/
                    @media (max-width:$tiny){
                        width: 95%;
                    }//3350

                }//DESCRIPTION


/*-------------------------------------------------------------------INSET (950)*/            
                @media (max-width:$innerBreak){
                    position:relative;
                    margin-top:-80px;

                    .kstl-rich-text{
                        padding-top:0px;
                    }
                }//950




            }//INSET
        }//SLIDE

    }//WHOLE


/*IMAGES------------------------------------------------------------------------*/
.kstl-image-wrapper{
    width:100%;
    line-height:0px;
    .kstl-responsive-image{
        width:100%;
        img{
            width:100%;
        }//IMG
    }//RESPONSIVE IMAGE

    @media (max-width:$lap){
        padding-bottom:20px;
    }
}//KSTL IMAGE WRAPPER

/*CENTERED CONTENT--------------------------------------------------------------*/
}                                                                               //
/*------------------------------------------------------------------------------*/